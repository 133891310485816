import { AbstractControl, ValidatorFn } from "@angular/forms";

export function PhoneValidator(control: AbstractControl): ValidatorFn {
  return () => {
    const regex = new RegExp(/^(?:(?:\+?[0-9]{2,4})?[ ]?[6789][0-9 ]{8,13})$/);

    if (!control.value) {
      return null;
    }

    if (regex.test(control.value)) {
      return null;
    } else {
      return { phone: true };
    }
  };
}

