import { Category, Genre } from '../models';
import { GenreDTO } from './genre.dto';

export class CategoryDTO {
  id: number;
  name: string;
  tag: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  genres?: GenreDTO[];

  static forClient(entity: CategoryDTO): Category {
    const model = new Category();

    model.id = entity.id;
    model.name = entity.name;
    model.tag = entity.tag;

    if (entity.genres) {
      model.genres = entity.genres.map((genre) => GenreDTO.forClient(genre));
    } else {
      model.genres = null;
    }

    return model;
  }
}
