import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDatePipe } from 'src/app/pipes/formatDate.pipe';
import { GetImagePipe } from './get-image.pipe';
import { SanitizeHtmlPipe } from './sanitizeHtml.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { FormatTeamCodePipe } from './format-team-code.pipe';
import { FormatShortDatePipe } from './format-short-date.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FormatDatePipe,
    FormatShortDatePipe,
    FormatTimePipe,
    GetImagePipe,
    SanitizeHtmlPipe,
    FormatTeamCodePipe
  ],
  exports: [
    FormatDatePipe,
    FormatShortDatePipe,
    FormatTimePipe,
    GetImagePipe,
    SanitizeHtmlPipe,
    FormatTeamCodePipe
  ]
})
export class PipesModule { }
