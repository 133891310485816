import { Cart } from "./cart.model";

export class DiscountApplication {
  discount: {
    type: string;
    applied: boolean;
    eventSlug?: number;
    rrppCode?: string;
  };

  cart: Cart;
}