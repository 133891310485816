import { HttpHeaders } from '@angular/common/http';
import { Team } from '../../models/user/team.model';
import { Injectable } from '@angular/core';
import { AddRRPPDTO, CreateTeamLeaderDTO } from 'src/app/models';
import { XipOcioService } from '../service/service';
import { TeamDTO } from 'src/app/DTO/team.dto';
import { Observable, from, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends XipOcioService {

  getTeamLeadersByPromoterId(id: number): Observable<Team[]> {
    return this.get<any[]>(`teams`, { promoterId: id })
      .pipe(
        map((response: any) => response?.map(x => TeamDTO.forClient(x)))
      );
  }

  createTeam(team: CreateTeamLeaderDTO) {
    return from(this.storage.get('token', false))
      .pipe(
        switchMap((token: string) => {
          const headers = new HttpHeaders().set('Authorization', token);

          return this.post('teams', { data: team }, headers)
            .pipe(
              map((response) => {
                if (response.error) {
                  this.toast.error('Error al crear el equipo');
                  return;
                }

                this.toast.success('Equipo creado correctamente');
                return response;
              })
            );
        })
      );
  }

  addRRPP(rrpp: AddRRPPDTO) {
    throw new Error('NOT IMPLEMENTED');
    // return this.postPromise('team-leader/rrpp', rrpp);
  }

  getTeamByCode(code: string): Observable<Team> {
    return this.get(`teams/code/${code}`)
      .pipe(
        map((response: any) => TeamDTO.forClient(response))
      );
  }

  addLeaderToTeam(teamId: number, leaderId: number) {
    return this.post(`teams/leader`, { teamId, leaderId });
  }

  deleteTeamLeaderFromTeam(teamId: number) {
    return this.delete(`teams/leader/${teamId}`);
  }

  getTeamsLeadedByUser() {
    return this.get('teams/leaded').pipe(
      map((response: any) => response.map(x => TeamDTO.forClient(x)))
    );
  }
}
