import { Team } from '../models/user/team.model';
import { FeeDTO } from './fee.dto';
import { PromoterDTO } from './promoter.dto';
import { RRPPDTO } from './rrpp.dto';
import { TeamRRPPDTO } from './team-RRPP.dto';
import { UserDTO } from './user.dto';

export class TeamDTO {
  id: number;
  name: string;
  code: string;
  promoter?: PromoterDTO;
  leader?: RRPPDTO;
  leaderFee?: FeeDTO;
  team_rrpps?: TeamRRPPDTO[];

  static forClient(entity: TeamDTO): Team {
    const model = new Team();

    model.id = entity.id;
    model.name = entity.name;
    model.code = entity.code;

    if (entity.promoter) {
      model.promoter = PromoterDTO.forClient(entity.promoter);
    }

    if (entity.leader) {
      model.leader = RRPPDTO.forClient(entity.leader);
    }

    if (entity.leaderFee) {
      model.leaderFee = FeeDTO.forClient(entity.leaderFee);
    }

    if (entity.team_rrpps) {
      model.teamRRPPs = entity.team_rrpps.map((team) => TeamRRPPDTO.forClient(team));
    }

    return model;
  }
}
