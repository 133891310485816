import { ToastController } from '@ionic/angular';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastController = inject(ToastController);

  constructor() { }

  async error(message?: string, duration: number = 3500) {
    if (!message) {
      message = "Se ha producido un error";
    }

    await this.present(message, 'danger', duration);
  }

  async success(message: string, duration: number = 3500) {
    await this.present(message, 'success', duration);
  }

  private async present(message: string, color?: string, duration: number = 3500) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      color: color ?? undefined
    });

    await toast.present();
  }
}
