import { Stock } from "../models/event/stock.model";
import { EventDTO } from "./event.dto";
import { StockLineDTO } from "./stock-line.dto";
import { TicketVariationDTO } from "./ticket-variation.dto";

export class StockDTO {
  id: number;
  event?: EventDTO;
  ticket_variation?: TicketVariationDTO;
  stock_lines: StockLineDTO[];
  limit?: number;
  remaining?: number;

  static forClient(entity: StockDTO): Stock {
    const model = new Stock();

    model.id = entity.id;

    if (entity?.remaining >= 0) {
      model.remaining = entity.remaining;
    }

    if (entity.limit) {
      model.limit = entity.limit;
    }

    if (entity.event) {
      model.event = EventDTO.forClient(entity.event);
    }

    if (entity.ticket_variation) {
      model.ticketVariation = TicketVariationDTO.forClient(entity.ticket_variation);
    }

    if (entity.stock_lines) {
      model.stockLines = entity.stock_lines.map((sl) => StockLineDTO.forClient(sl));
    }

    return model;
  }
}
