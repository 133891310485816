import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { env } from 'process';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/service/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xpo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @Output() onLoggin: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onRegister: EventEmitter<void> = new EventEmitter<void>();
  @Output() onForgot: EventEmitter<void> = new EventEmitter<void>();
  @Input() isModal = false;

  email: string;
  password: string;
  showPassword = false;

  googleUrl: string;

  constructor(
    private readonly authService: AuthService,
    public toastController: ToastController,
    private router: Router,
    private readonly recaptchaService: ReCaptchaV3Service,
    private readonly storage: StorageService
  ) {
    this.googleUrl = `${environment.api}/connect/google`;
  }

  captcha() {
    this.recaptchaService.execute('login')
      .pipe(first()).subscribe({
        next: (token) => {
          this.login();
        },
        error: async (err) => {
          const toast = await this.toastController.create({
            header: 'Error',
            message: 'Usuario o contraseña incorrectos',
            icon: 'warning-outline',
            position: 'top',
            color: 'danger',
            duration: 5000
          });
          await toast.present();

          if (this.isModal) {
            this.onLoggin.emit(false);
          }
        }
      });
  }

  googleLogin() {
    window.open(this.googleUrl, "Google", "width=500,height=500");

    const interval = setInterval(async () => {
      const storedToken = await this.storage.get('token', false);
      const storedUser = await this.storage.get('user', true);

      console.log('Stored', storedToken, storedUser)

      const logged = !!storedToken && !!storedUser;

      if (logged) {
        clearInterval(interval);

        if (!this.isModal) {
          return this.router.navigate(['']);
        }

        this.onLoggin.emit(true);
      }
    }, 3000);
  }

  private login() {
    this.authService.login(this.email, this.password)
      .pipe(
        first()
      )
      .subscribe({
        next: (login) => {
          this.authService.init()
            .pipe(
              first()
            )
            .subscribe((usr) => {
              if (!this.isModal) {
                return this.router.navigate(['']);
              }

              if (usr.id) {
                this.onLoggin.emit(true);
              } else {
                this.onLoggin.emit(false);
              }
            });
        },
        error: async (err) => {
          const toast = await this.toastController.create({
            header: 'Error',
            message: 'Usuario o contraseña incorrectos',
            icon: 'warning-outline',
            position: 'top',
            color: 'danger',
            duration: 5000
          });
          await toast.present();

          if (this.isModal) {
            this.onLoggin.emit(false);
          }
        }
      });

  }

  goHome() {
    this.router.navigate(['']);
  }
}
