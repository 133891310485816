import { CartItem } from './cartItem.model';
import { Order } from './order.model';
import { CartStatus } from "./cart-status.enum";
import { User } from '../user';

export class Cart {
  id: number;
  status: CartStatus;
  items: CartItem[];
  order?: Order;
  user: User;
  ip: string;
  date: Date;

  static create(cart: Partial<Cart>): Cart {
    const model = new Cart();

    model.id = cart.id;
    model.status = cart.status;
    model.items = cart.items;
    model.order = cart.order;
    model.user = cart.user;
    model.ip = cart.ip;
    model.date = new Date();

    return model;
  }
}
