import { OrderDTO } from './order.dto';
import { BillingInfo, Order } from '../models';
import { PopulationDTO } from './population.dto';
import { Population } from '../models/event/population.model';

export class BillingInfoDTO {
  id: number;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  DNI: string;
  order?: OrderDTO;

  static forClient(entity: BillingInfoDTO): BillingInfo {
    const model = new BillingInfo();

    model.id = entity.id;
    model.name = entity.name;
    model.lastName = entity.lastName;
    model.phone = entity.phone;
    model.email = entity.email;

    if (entity.order) {
      model.order = OrderDTO.forClient(entity.order);
    }

    if (entity.DNI) {
      model.DNI = entity.DNI;
    }

    return model;
  }
}
