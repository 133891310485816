import { Fee } from '../generic/fee.model';
import { RRPP } from './RRPP.model';
import { Team } from './team.model';

export class TeamRRPP {
  id: number;
  team: Team;
  active: boolean;
  rrppFee: Fee;
  rrpp?: RRPP;

  static create(teamRrpp: Partial<TeamRRPP>): TeamRRPP {
    const model = new TeamRRPP();

    model.id = teamRrpp.id;
    model.active = teamRrpp.active;

    if (teamRrpp.team) {
      model.team = Team.create(teamRrpp.team);
    }

    if (teamRrpp.rrppFee) {
      model.rrppFee = Fee.create(teamRrpp.rrppFee);
    }

    if (teamRrpp.rrpp) {
      model.rrpp = RRPP.create(teamRrpp.rrpp);
    }

    return model;
  }
}
