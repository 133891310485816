import { Population } from './../event/population.model';
export class Address {
  id: number;
  line1: string;
  line2?: string;
  country: string;
  population: Population;
  locationX: string;
  locationY: string;
  postal: string;

  static create(address: Partial<Address>): Address {
    const model = new Address();

    model.id = address.id;
    model.line1 = address.line1;
    model.line2 = address.line2;
    model.country = address.country;
    model.locationX = address.locationX;
    model.locationY = address.locationY;
    model.postal = address.postal;

    if (address.population) {
      model.population = Population.create(address.population);
    }

    return model;
  }
}
