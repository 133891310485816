import { StockLine } from "../models/event/stock-line.model";
import { OrderItemDTO } from "./order-item.dto";
import { StockDTO } from "./stock.dto";

export class StockLineDTO {
  id: number;
  blockDate: Date;
  buyDate?: Date;
  order_item: OrderItemDTO;
  stock?: StockDTO;

  static forClient(entity: StockLineDTO): StockLine {
    const model = new StockLine();

    model.id = entity.id;
    model.blockDate = entity.blockDate;
    model.buyDate = entity.buyDate;

    if (entity.order_item) {
      model.orderItem = OrderItemDTO.forClient(entity.order_item);
    }

    if (entity.stock) {
      model.stock = StockDTO.forClient(entity.stock);
    }

    return model;
  }
}