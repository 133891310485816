import { IonInput } from '@ionic/angular';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'xpo-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  @Input() currentImg: string;
  @Input() type: string;
  @Input() id: string;

  @Output() deleteImage: EventEmitter<any> = new EventEmitter();
  @Output() imgChanged: EventEmitter<any> = new EventEmitter();
  @Output() saveImage: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileInput') fileInput: IonInput;

  previewImg;
  maxSize = 2097152; // 2MB in Bytes
  fileErrors = {
    size: false,
    aspectRatio: false
  };

  async openFileSelector() {
    const element = await this.fileInput.getInputElement();
    element.click();
  }

  onFileChange(event) {
    const file = event.target.files[0];
    const blob = file.slice(0, file.size, 'image/png');

    const fileName = `${this.id}.${file.name.split('.')[1]}`;
    const fileType = file.type;

    const photo = new File([blob], fileName, { type: fileType });

    if (photo.size > this.maxSize) {
      this.fileErrors.size = true;
    }

    const reader = new FileReader();
    reader.readAsDataURL(photo);
    reader.onload = (_event) => {
      const image = new Image();
      image.src = _event.target.result as string;
      image.onload = (rs: any) => {
        const height = rs.currentTarget.height;
        const width = rs.currentTarget.width;

        if (width !== height) {
          this.fileErrors.aspectRatio = true;
        }
      };

      this.previewImg = reader.result;

      this.imgChanged.emit(file);
    };
  }

  resetImage() {
    this.previewImg = null;
    this.imgChanged.emit(null);

    this.fileErrors = {
      size: false,
      aspectRatio: false
    };
  }
}
