import { OrderDTO } from './order.dto';
import { CartStatus } from "../models/order/cart-status.enum";
import { Cart } from "../models/order/cart.model";
import { CartItemDTO } from "./cart-item.dto";
import { UserDTO } from './user.dto';

export class CartDTO {
  id: number;
  status: CartStatus;
  cart_items: CartItemDTO[];
  order: OrderDTO;
  user: UserDTO;
  ip: string;
  updatedAt: Date;

  static forClient(entity: CartDTO): Cart {
    const model = new Cart();

    model.id = entity.id;
    model.status = entity.status;
    model.ip = entity.ip;

    if (entity.cart_items) {
      model.items = entity.cart_items.map((item) => CartItemDTO.forClient(item));
    }

    if (entity.order) {
      model.order = OrderDTO.forClient(entity.order);
    }

    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    }

    if (entity.updatedAt) {
      model.date = entity.updatedAt;
    } else {
      model.date = new Date();
    }

    return model;
  }
}
