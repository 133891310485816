import { RRPPRequest } from "../models/requests/rrpp-request.model";
import { RequestDTO } from "./request.dto";
import { RRPPDTO } from "./rrpp.dto";

export class RRPPRequestDTO {
  id: number;
  request: RequestDTO;
  rrpp: RRPPDTO;

  static forClient(entity: RRPPRequestDTO): RRPPRequest {
    const model = new RRPPRequest();

    model.id = entity.id;
    model.request = RequestDTO.forClient(entity.request);
    model.rrpp = RRPPDTO.forClient(entity.rrpp);

    return model;
  }
}
