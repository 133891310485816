import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'xpo-cookie-consent',
  templateUrl: './cookie-consent.html',
  styleUrls: ['./cookie-consent.scss'],
})
export class CookieConsentComponent implements OnInit {
  forceDisplay = false;
  display = false;
  showDetail = false;

  form = {
    technical: true,
    preferences: false,
    analytics: false,
  }

  constructor(
    private cookieService: CookieService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('cookie')) {
      this.forceDisplay = true;
    } else {
      this.forceDisplay = false;
    }

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.urlAfterRedirects.includes('cookie')) {
          this.forceDisplay = true;
        } else {
          this.forceDisplay = false;
        }
      }
    });

    this.getTechCookie();
  }

  getTechCookie() {
    const techCookie = this.cookieService.get('xpo_allow_technical');
    if (!techCookie || Boolean(techCookie) === false) {
      this.display = true;
    }
  }

  save() {
    if (this.form.technical) {
      this.cookieService.put('xpo_allow_technical', "true");
      this.display = false;
    }

    if (this.form.preferences) {
      this.cookieService.put('xpo_allow_preferences', "true");
    } else {
      this.cookieService.put('xpo_allow_preferences', "false");
    }

    if (this.form.analytics) {
      this.cookieService.put('xpo_allow_analytics', "true");
    } else {
      this.cookieService.put('xpo_allow_analytics', "false");
    }

    this.getTechCookie();

    if (this.router.url.includes('cookie')) {
      this.forceDisplay = false;
    }
  }

}
