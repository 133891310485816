import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'xpo-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
})
export class QuantitySelectorComponent {
  @Input() value = 0;
  @Input() minValue = 1;
  @Input() maxValue: number | null = null;
  @Input() isDisabled: boolean = false;

  @Output() valueChange = new EventEmitter<number>();
  @Output() increased = new EventEmitter<number>();
  @Output() decreased = new EventEmitter<number>();

  increase() {
    if (this.isDisabled) {
      return;
    }

    if (this.maxValue && (this.value === this.maxValue)) {
      return;
    }

    this.value++;
    this.increased.emit(this.value);
    this.valueChange.emit(this.value);
  }

  decrease() {
    if (this.isDisabled) {
      return;
    }

    if (this.value <= this.minValue) {
      return;
    }

    this.value--;
    this.decreased.emit(this.value);
    this.valueChange.emit(this.value);
  }

}
