import { Ticket } from './ticket.model';

export class Attendee {
  id: number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  DNI?: string;
  ticket?: Ticket;

  static create(att: Partial<Attendee>): Attendee {
    const model = new Attendee();

    model.id = att.id;
    model.name = att.name;
    model.lastName = att.lastName;
    model.email = att.email;
    model.phone = att.phone;
    model.DNI = att.DNI;

    if (att.ticket) {
      model.ticket = Ticket.create(att.ticket);
    }

    return model;
  }
}
