import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { OrderService } from '../services/order/order.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckAttendeeValidator implements AsyncValidator {
  constructor(private orderService: OrderService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const attendee = {
      name: control?.parent?.get('name')?.value,
      lastName: control?.parent?.get('lastName')?.value,
      email: control?.parent?.get('email')?.value,
      phone: control?.parent?.get('phone')?.value,
      dni: control?.parent?.get('DNI')?.value
    };

    throw new Error('NOT IMPLEMENTED');
    // return this.orderService.validateAttendee(attendee);
  }
}
