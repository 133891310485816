import { User } from "../user";
import { Ticket } from "./ticket.model";

export class TicketGeneration {
  id: number;
  ticket?: Ticket;
  user: User;
  status: TicketGenerationStatus;
}

export enum TicketGenerationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Cancelled = 'Cancelled'
}
