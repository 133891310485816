import { Injectable } from '@angular/core';
import { Attendee } from 'src/app/models/ticket';
import { XipOcioService } from '../service/service';
import { CreateOrderDTO } from './DTO/create-order.dto';
import { Observable, from, map } from 'rxjs';
import { Order } from 'src/app/models';
import { OrderDTO } from 'src/app/DTO/order.dto';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends XipOcioService {
  test(order): Observable<any> {
    return from([{ ok: true }]);
  }

  buy(order: CreateOrderDTO): Observable<Order> {
    const body = {
      data: order
    };

    return this.post<OrderDTO>('orders', body)
      .pipe(
        map((response: OrderDTO) => {
          return OrderDTO.forClient(response) as Order;
        })
      );
  }

  confirm(body: any) {
    return this.post('payments', body);
  }

  validateAttendee(attendee: Partial<Attendee>) {
    throw new Error('NOT IMPLEMENTED');
    // return this.post(`order/validate-attendee`, attendee);
  }
}
