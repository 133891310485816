import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CheckAtendeeValidatorDirective } from '../validators/checkAttendee.directive';
import { ComponentsModule } from './components.module';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CookieConsentComponent } from '../components/cookie-consent/cookie-consent';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from 'src/environments/environment';
import { LoadingErrorInterceptor } from '../interceptors/loading-error.interceptor';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';

registerLocaleData(localeEs, 'es', localeExtraEs);

@NgModule({
  declarations: [
    AppComponent,
    CheckAtendeeValidatorDirective,
    CookieConsentComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es'
    }),
    ComponentsModule,
    CookieModule.withOptions(),
    RecaptchaV3Module,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    {
      provide: 'LOCALE_ID',
      useValue: 'es'
    },
    {
      provide: 'DEFAULT_CURRENCY_CODE',
      useValue: 'EUR'
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_SITE_KEY },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
