import { AbstractControl } from "@angular/forms";

export function ConfirmPasswordValidator(control: AbstractControl, matchingControl: AbstractControl) {

  return () => {
    // if (matchingControl.errors && !matchingControl.errors["confirmPassword"]) {
    //   return;
    // }

    if (control.value !== matchingControl.value) {
      return { confirmPassword: true };
    } else {
      return null;
    }
  };
}
