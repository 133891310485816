import { Payment } from './../models/order/payment.model';
import { OrderDTO } from './order.dto';
import { PaymentStatus } from "../models/order/payment.status.enum";
import { PaymentMethod } from '../models/order/payment-method.enum';

export class PaymentDTO {
  id: number;
  code: string;
  status: PaymentStatus;
  method: PaymentMethod;
  order?: OrderDTO;

  static forClient(entity: PaymentDTO): Payment {
    const model = new Payment();

    model.id = entity.id;
    model.code = entity.code;
    model.status = entity.status;

    if (entity.order) {
      model.order = OrderDTO.forClient(entity.order);
    }

    return model;
  }
}
