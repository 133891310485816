import { ToastService } from './../toast/toast.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable, catchError, delay, from, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { LoadingService } from '../loading/loading.service';
import { LoadingController } from '@ionic/angular';
import { Loading, LoadingType } from '../loading/DTO/loading.model';

@Injectable({
  providedIn: 'root',
})
export class XipOcioService {
  private api: string = environment.api;

  constructor(
    protected readonly http: HttpClient,
    protected readonly storage: StorageService,
    protected readonly toast: ToastService,
    protected readonly loading: LoadingService
  ) { }

  protected get<T>(endpoint: string, params?: any, headers?: HttpHeaders): Observable<T> {
    const load = this.loading.init();
    this.loading.setLoadingTrue(load);

    return this.http.get<T>(`${this.api}/${endpoint}`, { params, headers })
      .pipe(
        tap((r) => {
          this.loading.setLoadingFalse(load);
        }
        ),
        catchError((err) => {
          return of(err);
        })
      );
  }

  protected getText(endpoint: string): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(
      `${this.api}/${endpoint}`,
      {
        headers,
        responseType: 'text'
      }
    )
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  protected post<T>(endpoint: string, body: any, headers?: HttpHeaders): Observable<any> {
    return this.http.post<T>(`${this.api}/${endpoint}`, body, { headers })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  protected postText(endpoint: string, body: any): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.post(
      `${this.api}/${endpoint}`,
      body,
      {
        headers,
        responseType: 'text'
      }
    )
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  postRaw(endpoint: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.post(`${endpoint}`, body, { headers })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  protected put<T>(endpoint: string, body: any, options?): Observable<any> {
    return this.http.put(`${this.api}/${endpoint}`, body, options);
  }

  protected uploadFile(endpoint: string, file: any, refId: string, ref?: string, field?: string, headers?: HttpHeaders) {

    const form = new FormData();
    form.append('ref', ref ?? 'api::event.event');
    form.append('field', field ?? 'image');
    form.append('refId', refId);

    form.append('files', file);

    return from(this.storage.get('token', false))
      .pipe(
        switchMap((token) => {
          const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          });

          return this.http.post(`${this.api}/${endpoint}`, form, { headers, reportProgress: true, responseType: 'json' })
        })
      );
  }

  protected delete(endpoint: string, params?: any) {
    return from(this.storage.get('token', false))
      .pipe(
        switchMap((token) => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token
          });

          return this.http.delete(`${this.api}/${endpoint}`, {
            params,
            headers
          });
        })
      );
  }

  protected setInStore(key: string, value: any, parse: boolean): Observable<any> {
    return from(this.storage.set(key, value, parse));
  }

  protected getFromStore(key: string, parsed: boolean): Observable<any> {
    return from(this.storage.get(key, parsed));
  }

  protected removeFromStore(key: string): Observable<any> {
    return from(this.storage.remove(key));
  }

  protected clearStorage(): Observable<void> {
    return from(this.storage.clear());
  }

  protected async showError(message: string) {
    await this.toast.error(message);
  }
}
