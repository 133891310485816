import { PopulationDTO } from './population.dto';
import { Address } from '../models/generic/address.model';
import { Population } from '../models/event/population.model';

export class AddressDTO {
  id: number;
  line1: string;
  line2?: string;
  country: string;
  population: PopulationDTO;
  postal: string;
  locationX: string;
  locationY: string;

  static forClient(entity: AddressDTO): Address {
    const model = new Address();

    model.id = entity.id;
    model.line1 = entity.line1;
    model.line2 = entity.line2;
    model.country = entity.country;
    model.postal = entity.postal;
    model.locationX = entity.locationX;
    model.locationY = entity.locationY;

    if (entity.population) {
      model.population = PopulationDTO.forClient(entity.population) as Population;
    }

    return model;
  }
}
