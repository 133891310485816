import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/logged/logged.guard';
import { NotLogguedGuard } from '../guards/notLogged/not-logged.guard';
import { AdminRoleGuard } from '../guards/role/admin-role.guard';
import { PromoterRoleGuard } from '../guards/role/promoter-role.guard';
import { RRPPRoleGuard } from '../guards/role/rrpp-role.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'event/:slug',
    loadChildren: () => import('../pages/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'presale/:code',
    loadChildren: () => import('../pages/presale/presale.module').then(m => m.PresaleModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('../pages/my-tickets/my-tickets.module').then(m => m.MyTicketsPageModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('../pages/profile/profile.page.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('../pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminRoleGuard],
    canActivateChild: [AdminRoleGuard]
  },
  {
    path: 'promoter',
    loadChildren: () => import('../pages/promoter/promoter.module').then(m => m.PromoterPageModule),
    canActivate: [PromoterRoleGuard],
    canActivateChild: [PromoterRoleGuard]
  },
  {
    path: 'rrpp',
    loadChildren: () => import('../pages/rrpp/rrpp.module').then(m => m.RrppModule),
    canActivate: [RRPPRoleGuard],
    canActivateChild: [RRPPRoleGuard]
  },
  {
    path: 'rrpp-register/:code',
    loadChildren: () => import('../pages/rrpp-register/rrpp-register.module').then(m => m.RRPPRegisterPageModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('../pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'thank-you/:code',
    loadChildren: () => import('../pages/thank-you/thank-you.module').then(m => m.ThankYouModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'download-tickets',
    loadChildren: () => import('../pages/download-tickets/download-tickets.module').then(m => m.DownloadTicketsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [NotLogguedGuard]
  },
  {
    path: 'connect/google/redirect',
    loadChildren: () => import('../pages/connect-google/connect-google.module').then(m => m.ConnectGoogleModule),
    canActivate: [NotLogguedGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('../pages/register/register.module').then(m => m.RegisterPageModule),
    canActivate: [NotLogguedGuard]
  },
  {
    path: 'jobs',
    loadChildren: () => import('../pages/works/works.module').then(m => m.WorksModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('../pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../pages/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('../pages/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('../pages/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('../pages/cookies/cookies.module').then(m => m.CookiesModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('../pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'confirm-registration/:code',
    loadChildren: () => import('../pages/confirm-registration/confirm-registration.module').then(m => m.ConfirmRegistrationModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('../pages/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'add-rrpp/:code',
    loadChildren: () => import('../pages/add-rrpp/add-rrpp.module').then(m => m.AddRrppModule)
  },
  {
    path: 'add-leader/:code',
    loadChildren: () => import('../pages/add-leader/add-leader.module').then(m => m.AddLeaderModule)
  },
  // {
  //   path: 'control',
  //   loadChildren: () => import('../pages/access-control/access-control.module').then(m => m.AccessControlPageModule),
  //   canActivate: [PromoterRoleGuard],
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
