import { Request } from "../models/requests/request.model";
import { RRPPRequestDTO } from "./rrpp-request.dto";
import { UserDTO } from "./user.dto";

export class RequestDTO {
  id: number;
  status: RequestStatus;
  user: UserDTO;
  comment: string;
  event_request?: any;
  rrpp_request?: RRPPRequestDTO;
  sent: boolean;

  static forClient(entity: RequestDTO): Request {
    const model = new Request();

    model.id = entity.id;
    model.status = entity.status;
    model.comment = entity.comment;
    model.sent = entity.sent;

    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    }

    if (entity.rrpp_request) {
      model.rrppRequest = RRPPRequestDTO.forClient(entity.rrpp_request);
    }

    return model;
  }
}

export enum RequestStatus {
  Pending,
  Approved,
  Declined,
}
