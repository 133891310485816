import { City } from './city.model';

export class Population {
  id: number;
  name: string;
  city?: City;
  addresses?: any;
  places?: any;

  static create(pop: Partial<Population>): Population {
    const model = new Population();

    model.id = pop.id;
    model.name = pop.name;
    model.city = pop.city;
    model.addresses = pop.addresses;
    model.places = pop.places;

    return model;
  }
}
