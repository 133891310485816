import { Image, ImageFormatDefinition, ImageFormats } from '../models/generic/image.model';

export class ImageDTO {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: ImageFormatsDTO;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: any;
  provider: string;
  provider_metadata: any;
  createdAt: Date;
  updatedAt: Date;

  static forClient(entity: ImageDTO): Image {
    const model = new Image();

    model.id = entity.id;
    model.name = entity.name;
    model.alt = entity.alternativeText;
    model.caption = entity.caption;
    model.width = entity.width;
    model.height = entity.height;
    model.formats = ImageFormatsDTO.forClient(entity.formats);
    model.hash = entity.hash;
    model.ext = entity.ext;
    model.mime = entity.mime;
    model.size = entity.size;
    model.url = entity.url;
    model.previewUrl = entity.previewUrl;
    model.provider = entity.provider;
    model.providerMetadata = entity.provider_metadata;
    model.createdAt = entity.createdAt;
    model.updatedAt = entity.updatedAt;

    return model;
  }
}

export class ImageFormatsDTO {
  large: ImageFormatDefinitionDTO;
  small: ImageFormatDefinitionDTO;
  medium: ImageFormatDefinitionDTO;
  thumbnail: ImageFormatDefinitionDTO;

  static forClient(entity: ImageFormatsDTO): ImageFormats {
    const model = new ImageFormats();

    if (entity.large) {
      model.large = ImageFormatDefinitionDTO.forClient(entity.large);
    }

    if (entity.small) {
      model.small = ImageFormatDefinitionDTO.forClient(entity.small);
    }

    if (entity.medium) {
      model.medium = ImageFormatDefinitionDTO.forClient(entity.medium);
    }

    if (entity.thumbnail) {
      model.thumbnail = ImageFormatDefinitionDTO.forClient(entity.thumbnail);
    }

    return model;
  }
}

export class ImageFormatDefinitionDTO {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: any;
  size: number;
  width: number;
  height: number;

  static forClient(entity: ImageFormatDefinitionDTO): ImageFormatDefinition {
    const model = new ImageFormatDefinition();

    model.ext = entity.ext;
    model.url = entity.url;
    model.hash = entity.hash;
    model.mime = entity.mime;
    model.name = entity.name;
    model.path = entity.path;
    model.size = entity.size;
    model.width = entity.width;
    model.height = entity.height;

    return model;
  }
}
