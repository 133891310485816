// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qty-wrapper .qty-button {
  background-color: var(--ion-color-light-shade);
  border-radius: 4px;
  cursor: pointer;
  color: var(--ion-color-primary);
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  padding: 0.1rem;
}
.qty-wrapper .qty-button.disabled {
  cursor: not-allowed;
  background-color: var(--ion-color-light-shade);
  color: var(--ion-color-medium);
}
.qty-wrapper ion-label {
  font-size: 16px;
  line-height: 16px;
  padding: 0 6px;
  min-width: 43px;
  text-align: center;
}

:ng-deep .qty-button .button-native {
  padding: 0;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/quantity-selector/quantity-selector.component.scss"],"names":[],"mappings":"AAEE;EACE,8CAAA;EACA,kBAAA;EACA,eAAA;EACA,+BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AADJ;AAOI;EACE,mBAAA;EACA,8CAAA;EACA,8BAAA;AALN;AASE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;AAPJ;;AAaI;EACE,UAAA;EACA,kBAAA;AAVN","sourcesContent":[".qty-wrapper {\n\n  .qty-button {\n    background-color: var(--ion-color-light-shade);\n    border-radius: 4px;\n    cursor: pointer;\n    color: var(--ion-color-primary);\n    width: 16px;\n    height: 16px;\n    text-align: center;\n    line-height: 16px;\n    padding: 0.1rem;\n\n    &:hover {\n      // background-color: var(--ion-color-medium-shade);\n    }\n\n    &.disabled {\n      cursor: not-allowed;\n      background-color: var(--ion-color-light-shade);\n      color: var(--ion-color-medium);\n    }\n  }\n\n  ion-label {\n    font-size: 16px;\n    line-height: 16px;\n    padding: 0 6px;\n    min-width: 43px;\n    text-align: center;\n  }\n}\n\n:ng-deep {\n  .qty-button {\n    .button-native {\n      padding: 0;\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
