import { format } from "date-fns";
import { StatsTime, StatsTimeMilliseconds } from "./stats-time.enum";
import { es } from "date-fns/locale";
import { hexToRgb } from "src/app/utils/hex-to-rgb";
import { getDatesBetween } from "src/app/utils/get-days-between";

export type EventStatsData = EventStatsDataItem[];

export class EventStatsDataItem {
  price: number;
  fee: number;
  rrppFee: number;
  updatedAt: number;
  rrpp: string;
  variation: string;
  status: string;
  quantity: number;
  color: string;

  constructor(data: Partial<EventStatsDataItem>) {
    this.price = data.price;
    this.fee = data.fee;
    this.rrppFee = data.rrppFee;
    this.updatedAt = data.updatedAt;
    this.rrpp = data.rrpp;
    this.variation = data.variation;
    this.status = data.status;
    this.quantity = data.quantity;
    this.color = data.color;
  }

  static getLiveStats(stats: EventStatsData, time: StatsTime): EventStatsLive {
    const filteredStats = this.filterByDate(stats, time);

    const statsMap = new Map<string, EventStatsLiveItem>();

    filteredStats.forEach((stat) => {
      if (statsMap.has(stat.variation)) {
        const currentStat = statsMap.get(stat.variation);

        if (stat.status === 'Paid') {
          statsMap.set(stat.variation, {
            variation: stat.variation,
            sales: currentStat.sales + stat.quantity,
            total: currentStat.total + stat.price,
            rrppSales: stat.rrpp ? currentStat.rrppSales + stat.quantity : currentStat.rrppSales,
            rrppTotal: stat.rrpp ? currentStat.rrppTotal + stat.price : currentStat.rrppTotal,
            rrppFee: currentStat.rrppFee + stat.rrppFee,
            pending: currentStat.pending,
          });
        } else {
          statsMap.set(stat.variation, {
            variation: stat.variation,
            sales: currentStat.sales,
            total: currentStat.total,
            rrppSales: currentStat.rrppSales,
            rrppTotal: currentStat.rrppTotal,
            rrppFee: currentStat.rrppFee,
            pending: currentStat.pending + stat.quantity,
          });
        }

      } else {

        if (stat.status === 'Paid') {
          statsMap.set(stat.variation, {
            variation: stat.variation,
            sales: stat.quantity,
            total: stat.price,
            rrppSales: stat.rrpp ? stat.quantity : 0,
            rrppTotal: stat.rrpp ? stat.price : 0,
            rrppFee: stat.rrppFee,
            pending: 0,
          });
        } else {
          statsMap.set(stat.variation, {
            variation: stat.variation,
            sales: 0,
            total: 0,
            rrppSales: 0,
            rrppTotal: 0,
            rrppFee: 0,
            pending: stat.quantity,
          });
        }
      }
    });

    return statsMap;
  }

  static getDaySales(stats: EventStatsData): DaySalesStats {
    let result: DaySalesStats = { labels: [], datasets: [] };

    const validStats = stats.filter((stat) => stat.status === 'Paid');
    const variationsSet = new Set<string>();
    validStats.forEach((stat) => variationsSet.add(stat.variation));

    const variations = Array.from(variationsSet);

    const days = validStats
      .map((stat) => new Date(stat.updatedAt))
      .sort((a, b) => a.getTime() - b.getTime());

    const dates = getDatesBetween(days[0], days[days.length - 1]);

    result.labels = dates.map((date) => dateToDayStatDate(date.toISOString()));
    result.datasets = new Array(variations.length);

    variations.forEach((variation, index) => {
      const variationStats = validStats.filter((stat) => stat.variation === variation);

      const data = new Array(dates.length).fill(0);

      variationStats.forEach((stat) => {
        const dateIndex = dates.findIndex((date) => dateToDayStatDate(new Date(stat.updatedAt).toISOString()) === dateToDayStatDate(date.toISOString()));

        data[dateIndex] += stat.price;
      });

      const rgbColor = hexToRgb(variationStats[0].color);

      result.datasets[index] = {
        label: variation,
        data: data,
        backgroundColor: `rgba(${rgbColor}, 0.2)`,
        borderColor: `rgba(${rgbColor}, 1)`,
        borderWidth: 1,
      };
    });

    return result;
  }


  static filterByDate(stats: EventStatsData, time: number): EventStatsData {
    if (time === StatsTime.FULL) {
      return stats;
    }

    const now = Date.now();

    return stats.filter((stat) => {
      return (now - time) <= stat.updatedAt;
    });
  }
}

export function dateToDayStatDate(date: string): string {
  return format(new Date(date), "EEEEEE dd/MM", { locale: es });
}

export type EventStatsLive = Map<string, EventStatsLiveItem>;

export interface EventStatsLiveItem {
  variation: string;
  pending: number;
  sales: number;
  total: number;
  rrppSales: number;
  rrppTotal: number;
  rrppFee: number;
}

export interface DaySalesStats {
  labels: string[];
  datasets: DaySalesStatsItem[];
};
export interface DaySalesStatsItem {
  label: string;
  data: number[];
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
}
