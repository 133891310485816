export class Role {
  id: number;
  name: string;
  description: string;
  type: string;

  static create(role: Partial<Role>): Role {
    const model = new Role();

    model.id = role.id;
    model.name = role.name;
    model.description = role.description;
    model.type = role.type;

    return model;
  }
}
