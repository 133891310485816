export function getDatesBetween(start: Date, end: Date): Date[] {
  const today = new Date();
  const dates = [];
  let currentDate = new Date(start);

  if (end >= today) {
    end = today;
  }
  console.log(`START: ${start} - END: ${end}`);

  const addDays = function (days: number) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  while (currentDate <= addDays.call(end, 1)) {
    dates.push(new Date(currentDate));
    currentDate = addDays.call(currentDate, 1);
  }

  return dates;
}