import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  constructor() { }

  transform(date: any, formatStr: string = 'EEEE, dd/MM/yyyy'): string {
    return date ? format(parseISO(date), formatStr, {
      locale: es
    }) : '';
  }
}
