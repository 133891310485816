import { OrderItemDTO } from './order-item.dto';
import { TicketStatus } from './../models/ticket/ticket.status.enum';
import { RRPPDTO } from './rrpp.dto';
import { AttendeeDTO } from './attendee.dto';
import { TicketVariationDTO } from './ticket-variation.dto';
import { Ticket } from '../models';
import { TicketGenerationDTO } from './ticket-generation.dto';
import { VariationAddOnDTO } from './variation-addon.dto';

export class TicketDTO {
  id: number;
  code: string;
  rrpp: RRPPDTO;
  attendees?: AttendeeDTO[];
  status: TicketStatus;
  order_item?: OrderItemDTO;
  ticket_variation?: TicketVariationDTO;
  ticket_generation?: TicketGenerationDTO;
  variation_add_ons: VariationAddOnDTO[];

  static forClient(entity: TicketDTO): Ticket {
    const model = new Ticket();

    model.id = entity.id;
    model.code = entity.code;
    model.status = entity.status as TicketStatus;

    if (entity.attendees) {
      model.attendees = entity.attendees.map((att) => AttendeeDTO.forClient(att));
    }

    if (entity.rrpp) {
      model.RRPP = RRPPDTO.forClient(entity.rrpp);
    }

    if (entity.order_item) {
      model.orderItem = OrderItemDTO.forClient(entity.order_item);
    }

    if (entity.ticket_variation) {
      model.ticketVariation = TicketVariationDTO.forClient(entity.ticket_variation);
    }

    if (entity.ticket_generation) {
      model.generation = TicketGenerationDTO.forClient(entity.ticket_generation);
    }

    if (entity.variation_add_ons) {
      model.variationAddons = entity.variation_add_ons.map((va) => VariationAddOnDTO.forClient(va));
    }

    return model;
  }
}