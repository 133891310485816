import { CartDTO } from './cart.dto';
import { UserDTO } from 'src/app/DTO/user.dto';
import { Session } from '../models/generic/session.model';
export class SessionDTO {
  id: number;
  ip: string;
  createdAt: Date;
  user: UserDTO;
  cart: CartDTO;

  static forClient(entity: SessionDTO): Session {
    const model = new Session();

    model.id = entity.id;
    model.ip = entity.ip;
    model.createdAt = entity.createdAt;

    if (entity.cart) {
      model.cart = CartDTO.forClient(entity.cart);
    }

    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    }

    return model;
  }
}
