import { Injectable } from '@angular/core';
import { XipOcioService } from '../service/service';
import { Observable, firstValueFrom, from, lastValueFrom, map } from 'rxjs';
import { RRPPDTO } from 'src/app/DTO/rrpp.dto';
import { RRPP } from 'src/app/models';
import { RRPPRequestDTO } from 'src/app/DTO/rrpp-request.dto';
import { Team } from 'src/app/models/user/team.model';

@Injectable({
  providedIn: 'root'
})
export class RrppService extends XipOcioService {

  checkRRPP(code: string): Observable<RRPP> {
    return this.get<any>(`rrpps?filters[code][$eq]=${code}`)
      .pipe(
        map((response: { data: RRPPDTO[] }) => {
          if (response.data) {
            return RRPPDTO.forClient(response.data[0]) as RRPP;
          }

          return null;
        })
      );
  }

  async getSavedRRPPByEventSlug(slug: string) {
    return this.storage.get(`rrpp-${slug}`, true);
  }

  getRRPPStatsById(id: string) {
    // return this.getPromise(`rrpp/stats/${id}`);
  }

  getRRPPEventStats(eventId: number) {
    return this.get(`rrpps/stats/${eventId}`)
      .pipe(
        map((res) => {
          return res;
        })
      )
  }

  checkRRPPNickname(nick: string) {
    return this.get(`rrpps?filters[nickname][$eqi]=${nick}`);
  }

  checkRequest() {
    return this.get('rrpp-requests').pipe(
      map((response: RRPPRequestDTO[]) => {
        return response.map((x) => RRPPRequestDTO.forClient(x));
      })
    );
  }

  sendRequest(body) {
    return this.post('rrpp-requests', body);
  }

  getRRPPRequests() {
    return this.get('rrpp-requests').pipe(
      map((response: RRPPRequestDTO[]) => {
        return response.map((x) => RRPPRequestDTO.forClient(x));
      })
    );
  }

  updateRequests(ids: number[], approve: boolean) {
    return this.post('rrpp-requests/update-status', { ids, approve });
  }

  slugAvailable(slug: string) {
    return lastValueFrom(
      this.get(`rrpps?filters[code][$eq]=${slug}`)
        .pipe(
          map((response: any) => response.data.length === 0)
        )
    );
  }

  registerRRPP(body): Observable<RRPP> {
    return this.post<RRPPDTO>('rrpps/register', body)
      .pipe(
        map((response: RRPPDTO) => {
          return RRPPDTO.forClient(response);
        })
      );
  }

  addRRPPToTeam(rrpp: RRPP, team: Team) {
    return this.post('rrpps/add-to-team', { rrppId: rrpp.id, teamId: team.id });
  }
}
