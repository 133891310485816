import { Attendee } from '../models';
import { TicketDTO } from './ticket.dto';

export class AttendeeDTO {
  id: number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  DNI?: string;
  ticket?: TicketDTO;

  static forClient(entity: AttendeeDTO): Attendee {
    const model = new Attendee();

    model.id = entity.id;
    model.name = entity.name;
    model.lastName = entity.lastName;
    model.email = entity.email;
    model.phone = entity.phone;
    model.DNI = entity.DNI;

    if (entity.ticket) {
      model.ticket = TicketDTO.forClient(entity.ticket);
    }

    return model;
  }
}
