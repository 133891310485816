import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, catchError, first, from, map, switchMap, tap } from 'rxjs';
import { Attendee, CartItem } from 'src/app/models';
import { XipOcioService } from '../service/service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../service/storage.service';
import { ToastService } from '../toast/toast.service';
import { Cart } from 'src/app/models/order/cart.model';
import { CartStatus } from 'src/app/models/order/cart-status.enum';
import { CartDTO } from 'src/app/DTO/cart.dto';
import { LoadingService } from '../loading/loading.service';
import { DiscountApplicationDTO } from 'src/app/DTO/discount-application.dto';
import { DiscountApplication } from 'src/app/models/order/discount-application.model';

@Injectable({
  providedIn: 'root'
})
export class CartService extends XipOcioService {
  cart: ReplaySubject<Cart> = new ReplaySubject<Cart>(0);
  private currentCart = new Cart();

  constructor(
    protected readonly http: HttpClient,
    protected readonly storage: StorageService,
    protected readonly toast: ToastService,
    protected readonly loading: LoadingService
  ) {
    super(http, storage, toast, loading);
    this.currentCart.items = [];
    this.currentCart.status = CartStatus.Pending;
  }

  async addToCart(cartItem: CartItem) {
    for (let i = 0; i < (cartItem.quantity * cartItem.variation.pax); i++) {
      cartItem.attendees.push(new Attendee());
    }

    const item = this.findItem(cartItem);

    if (item) {
      this.increase(item, cartItem.quantity);
    } else {

      const variationIds = this.currentCart.items.map((x) => x.variation.id);

      if (cartItem.variation.private === true) {
        if (variationIds.includes(cartItem.variation.id)) {
          return;
        }
      }

      this.currentCart.items.push(cartItem);
    }


    await this.updateCartItems();
  }

  increase(cartItem: CartItem, quantity: number = 1) {
    const itemAddons = cartItem.variationAddons.map((addon) => addon.id);

    const index = this.currentCart.items.findIndex((itm) => {
      const cartItemAddons = itm.variationAddons.map((addon) => addon.id);

      const areSameAddons = (itemAddons.length === cartItemAddons.length) && (
        itemAddons.every((val, index) => val === cartItemAddons[index])
      );

      return (
        itm.event.id === cartItem.event.id
        && itm.variation.id === cartItem.variation.id
        && areSameAddons
      );
    });

    if (index < 0) {
      return;
    }

    if (this.currentCart.items[index].variation.private === true) {
      return;
    }

    this.currentCart.items[index].quantity += quantity;
    this.updateCartItems();
  }

  decrease(cartItem: CartItem) {
    const item = this.findItem(cartItem);

    if (!item) {
      return;
    }

    item.quantity--;
    this.updateCartItems();
  }

  deleteCartItem(cartItem: CartItem) {
    const itemAddons = cartItem.variationAddons.map((addon) => addon.id);

    const index = this.currentCart.items.findIndex((itm) => {
      const cartItemAddons = itm.variationAddons.map((addon) => addon.id);

      const areSameAddons = (itemAddons.length === cartItemAddons.length) && (
        itemAddons.every((val, index) => val === cartItemAddons[index])
      );

      return (
        itm.event.id === cartItem.event.id
        && itm.variation.id === cartItem.variation.id
        && areSameAddons
      );
    });

    if (index < 0) {
      return;
    }

    this.currentCart.items.splice(index);
    this.updateCartItems();
  }

  getPreviousCart(): Observable<Cart> {
    return this.get<CartDTO>('carts')
      .pipe(
        catchError((err) => {
          throw err;
        }),
        map((cart: CartDTO) => {
          return CartDTO.forClient(cart);
        })
      );
  }

  setCart(cart: Cart) {
    this.currentCart = cart;
    this.cart.next(this.currentCart);
  }

  verifyCode(code: string): Observable<DiscountApplication> {
    return this.get<DiscountApplicationDTO>('discounts', { code, cartId: this.currentCart.id })
      .pipe(
        map((discount: DiscountApplicationDTO) => {
          return DiscountApplicationDTO.forClient(discount);
        })
      );
  }

  private findItem(cartItem: CartItem) {

    return this.currentCart.items?.find((item: CartItem) => {
      const itemAddons = item.variationAddons.map((addon) => addon.id);
      const cartItemAddons = cartItem.variationAddons.map((addon) => addon.id);

      const areSameAddons = (itemAddons.length === cartItemAddons.length) && (
        itemAddons.every((val, index) => val === cartItemAddons[index])
      );

      return (
        item.event.id === cartItem.event.id
        && item.variation.id === cartItem.variation.id
        && areSameAddons
      );
    });
  }

  private async updateCartItems() {
    return new Promise((resolve, reject) => {
      this.updateRemoteCart()
        .pipe(first())
        .subscribe({
          next: (cart: CartDTO) => {
            this.currentCart = CartDTO.forClient(cart);

            this.cart.next(this.currentCart);
            resolve(this.currentCart);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }

  private updateRemoteCart() {
    return this.put(`carts/${this.currentCart.id}`, this.currentCart, {})
      .pipe(
        map((cart) => {
          return cart;
        })
      )
  }
}
