import { AuthService } from './../../services/auth/auth.service';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { Subscription, first } from 'rxjs';
import { CartItem, Commission, CommissionType, Promoter, ROLES, User } from 'src/app/models';
import { FeeType } from 'src/app/models/generic/fee.model';
import { Cart } from 'src/app/models/order/cart.model';
import { DiscountApplication } from 'src/app/models/order/discount-application.model';
import { CartService } from 'src/app/services/cart/cart.service';
import { Loading, LoadingType } from 'src/app/services/loading/DTO/loading.model';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { PromoterService } from 'src/app/services/promoter/promoter.service';
import { StorageService } from 'src/app/services/service/storage.service';

@Component({
  selector: 'xpo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private modalCtrl = inject(ModalController);

  sidenavOpened = false;
  subscriptions: Array<Subscription> = [];
  cartItems: Array<CartItem> = [];
  hideMenu = false;
  hideCart = false;
  showHeader = true;
  user: User;

  discount: string = null;

  selectedPromoter: Promoter;

  private previusCartItems = 0;
  private routesToHide: Array<string> = ['/checkout', '/login'];
  private previousRoute = '';
  loading: Loading | null = null;

  constructor(
    private menu: MenuController,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cartService: CartService,
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
    private readonly loadingService: LoadingService,
    private readonly promoterService: PromoterService,
    private readonly storageService: StorageService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.cartService.cart.subscribe(async (cart: Cart) => {
        const showToast = cart?.items?.length > this.previusCartItems;
        this.cartItems = cart.items;
        this.previusCartItems = this.cartItems?.length ?? 0;

        if (this.cartItems && this.cartItems.length) {
          const lastItemName = this.cartItems[this.cartItems.length - 1].event.name;

          if (showToast) {
            // const toast = await this.toastService.toastController.create({
            //   message: `${lastItemName} añadido al carrito.`,
            //   duration: 3500,
            //   position: 'top',
            //   color: 'primary',
            //   buttons: [
            //     {
            //       text: 'Ver',
            //       side: 'end',
            //       role: 'info',
            //       handler: () => {
            //         this.openCart();
            //         return Promise.resolve();
            //       }
            //     }
            //   ]
            // });

            // await toast.present();
          }
        }

      }),
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          this.previousRoute = ev.urlAfterRedirects;
          if (this.routesToHide.includes(ev.urlAfterRedirects)) {
            this.hideMenu = true;
            this.hideCart = true;
            this.showHeader = !['/login'].includes(ev.urlAfterRedirects);
          } else {
            this.hideMenu = false;
            this.hideCart = false;
            this.showHeader = true;
          }
        }
      }),
      this.authService.getUser()
        .subscribe((user: User) => {
          this.user = user;
        }),
      this.loadingService.allLoadings
        .subscribe((loadings: Loading[]) => {
          if (loadings.length) {
            this.loading = loadings[0];
          } else {
            this.loading = null;
          }
        }),
      this.promoterService.selectedPromoter$
        .subscribe((promoter: Promoter) => {
          this.selectedPromoter = promoter;
        })
    );
  }

  openSideNav() {
    this.menu.enable(true, 'menu');
    this.menu.open('menu');
  }

  openCart() {
    this.menu.enable(true, 'cart');
    this.menu.open('cart');
  }

  async navigate(route: string) {
    await this.router.navigate([`./${route}`], { relativeTo: this.route, replaceUrl: true });
    this.menu.close('menu');
  }

  async toCheckout() {
    const isLogged = await this.authService.isLogged();

    if (!isLogged) {
      const modal = await this.modalCtrl.create({
        component: LoginModalComponent,
        cssClass: 'login-modal',
        showBackdrop: true,
        backdropDismiss: false,
      });

      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        return this.router.navigate(['/checkout']);
      } else {
        return this.router.navigate(['register']);
      }
    }

    this.router.navigate(['checkout'], { replaceUrl: true });
    this.menu.close('cart');
  }

  getSubTotal(): number {
    let subtotal = 0;
    this.cartItems.forEach((item: CartItem) => {
      const addOnsTotal = item.variationAddons.reduce((acc, addon) => acc + addon.price, 0);

      subtotal += (item.quantity * (item.variation.price + addOnsTotal));
    });

    return subtotal;
  }

  getCosts(): number {
    let costs = 0;

    this.cartItems.forEach((item: CartItem) => {
      const addOnsTotal = item.variationAddons.reduce((acc, addon) => acc + addon.price, 0);
      let unitFee = 0;

      if (item.variation.fee.type === FeeType.FIXED) {
        unitFee = item.variation.fee.value;
      } else {
        unitFee = ((item.variation.price + addOnsTotal) * (item.variation.fee.value / 100));
      }

      unitFee = unitFee;

      costs += item.quantity * unitFee;
    });

    return costs;
  }

  getTotal(): number {
    return this.getSubTotal() + this.getCosts();
  }

  increaseQuantity(item: CartItem) {
    this.cartService.increase(item, 1);
  }

  decreaseQuantity(item: CartItem) {
    if (item.quantity > 1) {
      this.cartService.decrease(item);
    }
  }

  deleteItem(item: CartItem) {
    this.cartService.deleteCartItem(item);
  }

  goBack() {
    this.router.navigateByUrl('');
  }

  canAccess(role: string) {
    let mode = null;

    if (role.includes('#')) {
      const parts = role.split('#');
      role = parts[0];
      mode = parts[1];
    }

    const definedRoles = Object.values(ROLES).map((r) => r.toString());
    const userRole = this.user?.role?.name;

    if (this.user && userRole === "Admin") {
      return true;
    }

    if ((!this.user || !definedRoles.includes(role)) && role !== "Guest") {
      return false;
    }

    switch (role) {
      case "Public":
        return true;

      case "Customer":
        return userRole !== 'Public';

      case "RRPP":
        if (mode === 'leader') {
          return (
            userRole === 'Admin' ||
            (
              userRole === 'RRPP' &&
              this.user?.rrpp?.teamsLeaded?.length > 0
            )
            ||
            (
              userRole === 'Promoter' &&
              this.user?.rrpp?.teamsLeaded?.length > 0
            )
          );
        }
        return ['RRPP', 'Team Leader', 'Promoter', 'Admin'].includes(userRole);

      case "Team Leader":
        return ['Team Leader', 'Promoter', 'Admin'].includes(userRole);

      case "Promoter":
        if (mode === 'leader') {
          return (
            userRole === 'Admin' ||
            (
              userRole === 'Promoter' &&
              this.user?.rrpp?.teamsLeaded?.length > 0
            )
          );
        }
        return ['Promoter', 'Admin'].includes(userRole);

      case "Controller":
        return ['Controller', 'Promoter', 'Admin'].includes(userRole);

      case "Admin":
        return ['Admin'].includes(userRole);
    }

    return false;
  }

  accessOnly(role: string) {
    const userRole = this.user?.role.name;

    return userRole === "Admin" || role === userRole;
  }

  async verifyCode() {
    const loadingVerification = this.loadingService.init(false, LoadingType.toast, 'Verificando código...');

    await this.loadingService.setLoadingTrue(loadingVerification);

    this.cartService.verifyCode(this.discount)
      .pipe(
        first()
      )
      .subscribe({
        next: (applied: DiscountApplication) => {

          if (
            applied?.discount?.type === 'rrpp'
            && applied?.discount?.applied === true
            && applied?.discount?.eventSlug
            && applied?.discount?.rrppCode
          ) {
            this.discount = null;


            this.storageService.set(`rrpp-${applied.discount.eventSlug}`, {
              code: applied.discount.rrppCode,
              date: new Date()
            }, true)

            this.toastService.success('RRPP aplicado');

            this.cartService.setCart(applied.cart);
          } else {
            this.toastService.error('Código inválido');
          }


          this.loadingService.setLoadingFalse(loadingVerification);
        },
        error: (err) => {
          this.discount = null;
          this.loadingService.setLoadingFalse(loadingVerification);
        }
      });
  }

  async logout() {
    this.authService.logout()
      .pipe(
        first()
      ).subscribe({
        complete: async () => {
          this.menu.close('cart');
          await this.router.navigateByUrl('');
          location.reload();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x?.unsubscribe());
  }
}
