import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { ToastService } from '../services/toast/toast.service';
import { StorageService } from '../services/service/storage.service';

@Injectable()
export class LoadingErrorInterceptor implements HttpInterceptor {
  excludedUrls = [
    'email-confirmation',
    'payments',
  ];

  constructor(
    private readonly toastService: ToastService,
    private readonly storage: StorageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const excluded = this.excludedUrls.some(url => request.url.includes(url));

    if (excluded) {
      return next.handle(request);
    };

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          console.log('ERR INTERCEPTOR')
          let errorMsg = 'Se ha producido un error, por favor inténtelo de nuevo';

          switch (error.status) {
            case 400:
              errorMsg = `⚠️ ${errorMsg}`;
              break;

            case 401 || 403:


              errorMsg = `⛔ Permiso denegado 🔒`;
              break;

            case 412:
              errorMsg = `📓 ${error?.error?.error?.message ?? errorMsg}`;
              break;

            case 504 || 408:
              errorMsg = `⏲️ ${errorMsg}`;
              break;
          }

          this.toastService.error(errorMsg);
          throw error;
        })
      );
  }
}
