import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'getImage'
})
export class GetImagePipe implements PipeTransform {
  private api: string = environment.api;

  transform(value: string, arg: string): string {
    return `${this.api.replace('/api', '')}${value}`;
  }

}
