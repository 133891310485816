import { UserDTO } from './user.dto';
import { CartDTO } from './cart.dto';
import { Order } from '../models';
import { BillingInfoDTO } from './billing-info.dto';
import { OrderItemDTO } from './order-item.dto';
import { PaymentDTO } from './payment.dto';
import { AddressDTO } from './address.dto';
import { OrderStatus } from '../models/order/order-status.enum';
import { PopulationDTO } from './population.dto';

export class OrderDTO {
  id: number;
  notifyWhatsapp: boolean;
  user?: UserDTO;
  billingInfo?: BillingInfoDTO;
  order_items: OrderItemDTO[];
  cart?: CartDTO;
  payment?: PaymentDTO;
  createdAt: Date;
  address: AddressDTO;
  isGeneration: boolean;
  status: OrderStatus;
  population: PopulationDTO;

  static forClient(entity: OrderDTO): Order {
    const model = new Order();

    model.id = entity.id;
    model.notifyWhatsapp = entity.notifyWhatsapp;
    model.createdAt = entity.createdAt;
    model.isGeneration = entity.isGeneration;
    model.status = entity.status;

    if (entity.order_items) {
      model.orderItems = entity.order_items.map((item) => OrderItemDTO.forClient(item));
    }

    if (entity.address) {
      model.address = AddressDTO.forClient(entity.address);
    }

    if (entity.population) {
      model.population = PopulationDTO.forClient(entity.population);
    }

    if (entity.billingInfo) {
      model.billingInfo = BillingInfoDTO.forClient(entity.billingInfo);
    }

    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    }

    if (entity.cart) {
      model.cart = CartDTO.forClient(entity.cart);
    }

    if (entity.payment) {
      model.payment = PaymentDTO.forClient(entity.payment);
    }

    return model;
  }
}
