import { EventStats, TicketVariation } from 'src/app/models';
export class VariationStats {
  name: string;
  maxStock?: number;
  stock?: number;
  infinite: boolean;
  sales: number;
  tickets: number;
  generated: number;

  static getVariationStats(variation: TicketVariation): VariationStats {
    const model = new VariationStats;
    model.name = variation.name;

    model.stock = variation.stock?.limit;
    model.infinite = !model.stock;
    model.sales = 0;
    model.tickets = 0;
    model.generated = 0;

    const validTickets = EventStats.getValidTickets(variation.tickets);


    validTickets.forEach((tk) => {
      if (tk.generation && tk.generation.status === 'Approved') {
        model.generated++;
      } else {
        model.tickets++;
        model.sales += tk?.orderItem?.unitPrice;
      }
    });

    console.log(model.name, model.generated)

    return model;
  }

  getVariationDoughnutDatasets() {
    if (!this.stock) {
      return null;
    }

    return [
      {
        data: [this.tickets, (this.stock - this.tickets)],
        backgroundColor: ['#f6a91c', '#e5e5e5']
      },
    ];
  }
}
