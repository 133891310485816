import { Cart } from "../order/cart.model";
import { User } from "../user";

export class Session {
    id: number;
    ip: string;
    createdAt: Date;
    user?: User;
    cart?: Cart;
}
