import { Population } from "../event/population.model";
import { Address } from "../generic/address.model";
import { Order } from "./order.model";

export class BillingInfo {
  id: number;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  DNI: string
  population: Population;
  order?: Order;

  static create(bil: Partial<BillingInfo>): BillingInfo {
    const model = new BillingInfo();

    model.id = bil.id;
    model.name = bil.name;
    model.lastName = bil.lastName;
    model.phone = bil.phone;
    model.email = bil.email;
    model.DNI = bil.DNI;
    model.population = bil.population;
    model.order = bil.order;

    return model;
  }
}
