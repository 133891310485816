import { v4 as uuid } from "uuid";

export class Loading {
  id: string;
  loading: boolean;
  type: LoadingType;
  progress: number | null;
  messate?: string;
  toastEl?: HTMLIonLoadingElement;

  constructor(loading: boolean = true, type: LoadingType = LoadingType.global, message?: string) {
    this.id = uuid();
    this.loading = loading;
    this.type = type;
    this.progress = null;
    this.messate = message;
    this.toastEl = null;
  }
}

export enum LoadingType {
  global,
  toast
}
