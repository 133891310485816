import { DiscountApplication } from "../models/order/discount-application.model";
import { CartDTO } from "./cart.dto";

export class DiscountApplicationDTO {
  discount: {
    type: string;
    applied: boolean;
  };

  cart?: CartDTO;

  static forClient(entity: DiscountApplicationDTO): DiscountApplication {
    const model = new DiscountApplication();

    model.discount = entity.discount;
    model.cart = entity.cart ? CartDTO.forClient(entity.cart) : null;

    return model;
  }
}