import { TeamRRPP } from '../models/user/TeamRRPP.model';
import { FeeDTO } from './fee.dto';
import { RRPPDTO } from './rrpp.dto';
import { TeamDTO } from './team.dto';

export class TeamRRPPDTO {
  id: number;
  team: TeamDTO;
  active: boolean;
  rrpp_fee: FeeDTO;
  rrpp: RRPPDTO;

  static forClient(entity: TeamRRPPDTO): TeamRRPP {
    const model = new TeamRRPP();

    model.id = entity.id;
    model.active = entity.active ?? false;

    if (entity.team) {
      model.team = TeamDTO.forClient(entity.team);
    }

    if (entity.rrpp_fee) {
      model.rrppFee = FeeDTO.forClient(entity.rrpp_fee);
    }

    if (entity.rrpp) {
      model.rrpp = RRPPDTO.forClient(entity.rrpp);
    }

    return model;
  }
}
