import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { QuantitySelectorComponent } from './quantity-selector.component';

@NgModule({
  declarations: [
    QuantitySelectorComponent
  ],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  exports: [
    QuantitySelectorComponent
  ]
})
export class QuantitySelectorModule { }
