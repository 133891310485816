import { Population } from "../event/population.model";
import { Address } from "../generic/address.model";
import { User } from "../user";
import { BillingInfo } from "./billingInfo.model";
import { Cart } from "./cart.model";
import { OrderStatus } from "./order-status.enum";
import { OrderItem } from "./orderItem.model";
import { Payment } from "./payment.model";

export class Order {
  id: number;
  notifyWhatsapp: boolean;
  orderItems: OrderItem[];
  billingInfo?: BillingInfo;
  user?: User;
  cart?: Cart
  payment?: Payment;
  createdAt: Date;
  address: Address;
  isGeneration: boolean;
  status: OrderStatus;
  population: Population;

  static create(order: Partial<Order>): Order {
    const model = new Order();

    model.id = order.id;
    model.notifyWhatsapp = order.notifyWhatsapp;

    if (order.address) {
      model.address = Address.create(order.address);
    }

    if (order.orderItems) {
      model.orderItems = order.orderItems.map((item) => {
        return OrderItem.create(item);
      });
    }

    if (order.billingInfo) {
      model.billingInfo = BillingInfo.create(order.billingInfo);
    }

    if (order.user) {
      model.user = User.create(order.user);
    }

    if (order.cart) {
      model.cart = Cart.create(order.cart);
    }

    if (order.payment) {
      model.payment = Payment.create(order.payment);
    }

    return model;
  }
}
