import { RRPP } from '../models';
import { TeamRRPPDTO } from './team-RRPP.dto';
import { TeamDTO } from './team.dto';
import { UserDTO } from './user.dto';

export class RRPPDTO {
  id: number;
  nickname: string;
  code: string;
  active: boolean;
  user?: UserDTO;
  team_rrpps?: TeamRRPPDTO[];
  teamsLeaded?: TeamDTO[];

  static forClient(entity: RRPPDTO): RRPP {
    const model = new RRPP();

    model.id = entity.id;
    model.nickname = entity.nickname;
    model.code = entity.code;
    model.active = entity.active;

    if (entity.team_rrpps) {
      model.teamRRPPs = entity.team_rrpps.map((team) => TeamRRPPDTO.forClient(team));
    } else {
      model.teamRRPPs = null;
    }

    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    } else {
      model.user = null;
    }

    if (entity.teamsLeaded) {
      model.teamsLeaded = entity.teamsLeaded.map((team) => TeamDTO.forClient(team));
    } else {
      model.teamsLeaded = null;
    }

    return model;
  }
}
