import { Genre } from './../models/generic/genre.model';

export class GenreDTO {
  id: number;
  name: string;
  tag: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;

  static forClient(entity: GenreDTO): Genre {
    const model = new Genre();

    model.id = entity.id;
    model.name = entity.name;
    model.tag = entity.tag;

    return model;
  }
}
