import { Order } from "./order.model";
import { PaymentMethod } from "./payment-method.enum";
import { PaymentStatus } from "./payment.status.enum";

export class Payment {
  id: number;
  code: string;
  order?: Order;
  status: PaymentStatus;
  method: PaymentMethod;

  static create(pay: Partial<Payment>): Payment {
    const model = new Payment();

    model.id = pay.id;
    model.status = pay.status;
    model.method = pay.method;
    model.order = pay.order;

    return model;
  }
}
