import { Directive, forwardRef } from "@angular/core";
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { Observable } from "rxjs";
import { OrderService } from "../services/order/order.service";
import { CheckAttendeeValidator } from "./checkAttendee";

@Directive({
  selector: '[validateAtendee]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => CheckAtendeeValidatorDirective),
      multi: true
    }
  ]
})
export class CheckAtendeeValidatorDirective implements AsyncValidator {
  constructor(private checkAttendee: CheckAttendeeValidator) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {

    return this.checkAttendee.validate(control);
  }
}