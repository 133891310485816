export class Image {
  id: number;
  name: string;
  alt: string;
  caption: string;
  width: number;
  height: number;
  formats: ImageFormats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: any;
  provider: string;
  providerMetadata: any;
  createdAt: Date;
  updatedAt: Date;
}

export class ImageFormats {
  large: ImageFormatDefinition;
  small: ImageFormatDefinition;
  medium: ImageFormatDefinition;
  thumbnail: ImageFormatDefinition;
}

export class ImageFormatDefinition {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: any;
  size: number;
  width: number;
  height: number;
}
