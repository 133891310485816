export class EventRRPPStats {
  name?: string;
  ticketSales: Map<string, RRPPTicketSalesStats>;
  tickets: number;
  sales: number;
  tax: number;
}

export class RRPPTicketSalesStats {
  name: string;
  tickets: number;
  sales: number;
  tax: number;
}
