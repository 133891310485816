import { Fee } from '../generic/fee.model';
import { RRPP } from './RRPP.model';
import { TeamRRPP } from './TeamRRPP.model';
import { Promoter } from './promoter.model';
import { User } from './user.model';

export class Team {
  id: number;
  name: string;
  code: string;
  promoter?: Promoter;
  leader?: RRPP;
  leaderFee?: Fee;
  teamRRPPs?: TeamRRPP[];

  static create(team: Partial<Team>): Team {
    const model = new Team();

    model.id = team.id;
    model.name = team.name;
    model.code = team.code;

    if (team.promoter) {
      model.promoter = Promoter.create(team.promoter);
    }

    if (team.leader) {
      model.leader = RRPP.create(team.leader);
    }

    if (team.leaderFee) {
      model.leaderFee = Fee.create(team.leaderFee);
    }

    if (team.teamRRPPs && team.teamRRPPs.length) {
      model.teamRRPPs = team.teamRRPPs.map((teamRrpp) => {
        return TeamRRPP.create(teamRrpp);
      });
    }

    return model;
  }

  getActiveTeamRrpps(): TeamRRPP[] {
    return this.teamRRPPs.filter((teamRrpp) => teamRrpp.active);
  }

  getInactiveTeamRrpps(): TeamRRPP[] {
    return this.teamRRPPs.filter((teamRrpp) => !teamRrpp.active);
  }
}
