import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'formatTeamCode'
})
export class FormatTeamCodePipe implements PipeTransform {

  transform(value: string, isTeamLeader: boolean = false): string {
    if (!isTeamLeader) {
      return `${environment.front}/add-rrpp/${value}`;
    } else {
      return `${environment.front}/add-leader/${value}`;
    }
  }
}
