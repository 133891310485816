import { AddOn } from "../models/event/addon.model";
import { ImageDTO } from "./image.dto";

export class AddOnDTO {
  id: number;
  name: string;
  description: string;
  image: ImageDTO;

  static forClient(entity: AddOnDTO): AddOn {
    const model = new AddOn();

    model.id = entity.id;
    model.name = entity.name;
    model.description = entity.description;
    model.image = entity.image ? ImageDTO.forClient(entity.image) : null;

    return model;
  }
}