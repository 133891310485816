import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'xpo-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  private modalCtrl = inject(ModalController);
  private router = inject(Router);

  code: string;
  mode: 'rrpp' | 'team';

  show: 'login' | 'register' = 'login';

  constructor() { }

  ngOnInit() {
  }

  async onLogin(result) {
    if (!result) {
      return await this.modalCtrl.dismiss(null, 'cancel');
    }

    return await this.modalCtrl.dismiss(result, 'confirm');
  }

  async onRegister(result) {
    if (!result) {
      return await this.modalCtrl.dismiss(null, 'cancel');
    }

    this.show = 'login';
  }

  async showRegister() {
    this.show = 'register';
  }

  async showLogin() {
    this.show = 'login';
  }

  async onForgot() {
    await this.modalCtrl.dismiss(null, 'cancel');
    return this.router.navigate(['forgot-password']);
  }

  closeModal() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
