import { Event, TicketVariation } from "../event";
import { VariationAddOn } from "../event/variation-addon.model";
import { Attendee } from "../ticket";
import { RRPP } from "../user";
import { Cart } from "./cart.model";

export class CartItem {
  id: number;
  quantity: number;
  variation: TicketVariation;
  event: Event;
  attendees: Attendee[];
  rrpp?: RRPP;
  cart?: Cart;
  variationAddons: VariationAddOn[];

  constructor() {
    this.attendees = [];

    for (let i = 0; i < this.quantity; i++) {
      this.attendees.push(new Attendee());
    }
  }
}
