import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { first, map } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";

export const NotLogguedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.getUser()
    .pipe(
      first(),
      map((user) => {

        if (user?.id) {
          router.navigate(['/profile']);
        }

        return !user?.id;
      })
    );
};