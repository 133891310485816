import { Event, Ticket } from '../models';
import { EventStatus } from '../models/event/event-status.enum';
import { CategoryDTO } from './category.dto';
import { GenreDTO } from './genre.dto';
import { ImageDTO } from './image.dto';
import { PlaceDTO } from './place.dto';
import { PromoterDTO } from './promoter.dto';
import { StockDTO } from './stock.dto';
import { TicketVariationDTO } from './ticket-variation.dto';

export class EventDTO {
  id: number;
  name: string;
  image: ImageDTO;
  description: string;
  publishDate: Date;
  sellDate: Date;
  stopDate: Date;
  startDate: Date;
  endDate: Date;
  stock: StockDTO;
  ticket_variations: TicketVariationDTO[];
  adultRequired: boolean;
  uniqueTicket: boolean;
  dniRequired: boolean;
  featured: boolean;
  hasGenerations: boolean;
  hasGuestLists: boolean;
  status: string;
  place: PlaceDTO;
  genres: GenreDTO[];
  slug: string;
  categories: CategoryDTO[];
  promoters: PromoterDTO[];
  createdAt: string;
  updatedAt: string;
  scannerToken?: string;

  static forClient(entity: EventDTO): Event {
    const model = new Event();

    model.id = entity.id;
    model.name = entity.name;
    model.slug = entity.slug;
    model.description = entity.description;
    model.status = entity.status as EventStatus;
    model.adultRequired = entity.adultRequired;
    model.uniqueTicket = entity.uniqueTicket;
    model.dniRequired = entity.dniRequired;
    model.featured = entity.featured;
    model.hasGenerations = entity.hasGenerations;
    model.hasGuestLists = entity.hasGuestLists;
    model.publishDate = entity.publishDate;
    model.sellDate = entity.sellDate;
    model.stopDate = entity.stopDate;
    model.startDate = entity.startDate;
    model.endDate = entity.endDate;

    if (entity.stock) {
      model.stock = StockDTO.forClient(entity.stock);
    }

    if (entity.categories) {
      model.categories = entity.categories.map((cat) => CategoryDTO.forClient(cat));
    }

    if (entity.genres) {
      model.genres = entity.genres.map((gen) => GenreDTO.forClient(gen));
    }

    if (entity.promoters) {
      model.promoters = entity.promoters.map((pro) => PromoterDTO.forClient(pro));
    }

    if (entity.image) {
      model.image = ImageDTO.forClient(entity.image);
    }

    if (entity.place) {
      model.place = PlaceDTO.forClient(entity.place);
    }

    if (entity.ticket_variations) {
      model.ticketVariations = entity.ticket_variations.map((tv) => TicketVariationDTO.forClient(tv)).sort((a, b) => {


        if (a?.clientStock?.disabled === true && b?.clientStock?.disabled === false) {
          return 1;
        }

        if (a?.clientStock?.disabled === false && b?.clientStock?.disabled === true) {
          return -1;
        }


        // sort by Order
        const aOrder = a.order ?? 0;
        const bOrder = b.order ?? 0;

        return aOrder - bOrder;
      });
    }

    if (entity.scannerToken) {
      model.scannerToken = entity.scannerToken;
    }

    return model;
  }
}
