export interface Commission {
  id: string;
  name: string;
  value: number;
  type: CommissionType;
  area: CommissionArea;
}

export enum CommissionType {
  FIXED,
  PERCENT
}

export enum CommissionArea {
  CORPORATE,
  PEOPLE,
  CLIENT
}
