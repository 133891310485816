import { FeeZone } from './../models/generic/fee.model';
import { Fee, FeeType } from '../models/generic/fee.model';

export class FeeDTO {
  id: number;
  name: string;
  value: number;
  type: string;
  zone: string;
  createdAt: string;
  updatedAt: string;

  static forClient(entity: FeeDTO): Fee {
    const model = new Fee();

    model.id = entity.id;
    model.name = entity.name;
    model.value = entity.value;
    model.type = entity.type as FeeType;
    model.zone = entity.zone as FeeZone;

    return model;
  }
}
