import { TicketGeneration, TicketGenerationStatus } from "../models";
import { TicketDTO } from "./ticket.dto";
import { UserDTO } from "./user.dto";

export class TicketGenerationDTO {
  id: number;
  status: string;
  user?: UserDTO;
  ticket?: TicketDTO;

  static forClient(entity: TicketGenerationDTO): TicketGeneration {
    const model = new TicketGeneration();

    model.id = entity.id;
    if (entity.user) {
      model.user = UserDTO.forClient(entity.user);
    }

    if (entity.ticket) {
      model.ticket = TicketDTO.forClient(entity.ticket);
    }

    switch (entity.status) {
      case 'Pending':
        model.status = TicketGenerationStatus.Pending;
        break;

      case 'Cancelled':
        model.status = TicketGenerationStatus.Cancelled;
        break;

      default:
        model.status = TicketGenerationStatus.Approved;
        break;
    }

    return model;
  }
}