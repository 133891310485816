// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  position: fixed;
  bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper * {
  transition: all ease-in 600;
}
.wrapper .container {
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 2rem;
}
.wrapper .container .disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.wrapper img {
  width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cookie-consent/cookie-consent.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,2BAAA;AACJ;AAEE;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AAEI;EACE,mBAAA;EACA,oBAAA;AAAN;AAIE;EACE,WAAA;AAFJ","sourcesContent":[".wrapper {\n  position: fixed;\n  bottom: 30px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  * {\n    transition: all ease-in 600;\n  }\n\n  .container {\n    background-color: #f5f5f5;\n    border-radius: 12px;\n    padding: 2rem;\n\n    .disabled {\n      cursor: not-allowed;\n      pointer-events: none;\n    }\n  }\n\n  img {\n    width: 80px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
