import { City } from '../models/event/city.model';
import { PopulationDTO } from './population.dto';

export class CityDTO {
  id: number;
  name: string;
  populations?: PopulationDTO[];

  static forClient(entity: CityDTO): City {
    const model = new City();

    model.id = entity.id;
    model.name = entity.name;

    if (entity.populations) {
      model.populations = entity.populations.map((pop) => PopulationDTO.forClient(pop))
        .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    } else {
      model.populations = null;
    }

    return model;
  }
}
