import { Promoter } from '../models';
import { EventDTO } from './event.dto';
import { FeeDTO } from './fee.dto';
import { TeamDTO } from './team.dto';
import { UserDTO } from './user.dto';

export class PromoterDTO {
  id: number;
  name: string;
  defaultFee?: FeeDTO;
  owners?: UserDTO[];
  teams?: TeamDTO[];
  events?: EventDTO[];

  static forClient(entity: PromoterDTO): Promoter {
    const model = new Promoter();

    model.id = entity.id;
    model.name = entity.name;

    if (entity.owners) {
      model.owners = entity.owners.map((own) => UserDTO.forClient(own));
    }

    if (entity.teams) {
      model.teams = entity.teams.map((team) => TeamDTO.forClient(team));
    }

    if (entity.events) {
      model.events = entity.events.map((event) => EventDTO.forClient(event));
    }

    if (entity.defaultFee) {
      model.defaultFee = FeeDTO.forClient(entity.defaultFee);
    }

    return model;
  }
}
