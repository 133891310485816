// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blur {
  opacity: 0.5;
}

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  max-width: 250px !important;
}

.over {
  position: absolute;
  float: left;
  z-index: 99;
  width: 100%;
  height: 100%;
}

.over-icon {
  position: absolute !important;
  float: left !important;
  left: calc(50% - 30px) !important;
  top: calc(50% - 30px) !important;
  font-size: 60px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/image-upload/image-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGA;EACE,6BAAA;EACA,sBAAA;EACA,iCAAA;EACA,gCAAA;EACA,0BAAA;AAAF","sourcesContent":[".blur {\n  opacity: 0.5;\n}\n\n.img-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.img {\n  max-width: 250px!important;\n}\n\n.over {\n  position: absolute;\n  float: left;\n  z-index: 99;\n  width: 100%;\n  height: 100%;\n\n}\n\n.over-icon {\n  position: absolute!important;\n  float: left!important;\n  left: calc(50% - 30px)!important;\n  top: calc(50% - 30px)!important;\n  font-size: 60px!important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
