import { Injectable } from '@angular/core';
import { XipOcioService } from '../service/service';
import { map, switchMap, Observable, from, of } from 'rxjs';
import { SessionDTO } from 'src/app/DTO/session.dto';
import { Session } from 'src/app/models/generic/session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends XipOcioService {

  getCurrentSession(): Observable<Session> {
    let userId = null;

    return this.get('utils/ip')
      .pipe(
        switchMap((resIp: any) => {
          const ip = resIp.ip;

          return from(this.storage.get('user', true))
            .pipe(
              switchMap((user) => {
                if (user) {
                  userId = user.id;
                }

                let minDate = new Date();
                minDate.setDate(minDate.getDate() - 7);

                return this.get<any>(`sessions?populate[0]=user&populate[1]=cart.order&populate[2]=cart.cart_items.ticket_variation&populate[3]=cart.cart_items.rrpp&filters[$and][0][ip][$eq]=${ip}&filters[$and][1][createdAt][$gte]=${minDate.toISOString()}${userId ? '&filters[$and][2][user][id][$eq]=' + userId : ''}`)
                  .pipe(
                    switchMap((session) => {
                      if (session && session.meta.pagination.total > 0) {
                        return of(SessionDTO.forClient(session) as Session);
                      } else {

                        return this.createSession(ip, userId);
                      }
                    })
                  );

              })
            )
        })
      )
  }

  createSession(ip: string, userId: number): Observable<Session> {
    const body = {
      data: {
        ip: ip,
        user: userId
      }
    };

    return this.post('sessions?populate=user', body)
      .pipe(
        map((response) => {
          return SessionDTO.forClient(response) as Session;
        })
      );
  }
}
