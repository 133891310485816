import { BehaviorSubject, Observable, ReplaySubject, catchError, first, forkJoin, from, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Promoter, User } from 'src/app/models';
import { XipOcioService } from '../service/service';
import { PromoterService } from '../promoter/promoter.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../service/storage.service';
import { ToastService } from '../toast/toast.service';
import { UserDTO } from 'src/app/DTO/user.dto';
import { Role } from 'src/app/models/user/role.model';
import { RegisterDTO } from './DTO/register.dto';
import { LoadingService } from '../loading/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends XipOcioService {
  user$: BehaviorSubject<User> = new BehaviorSubject(null);
  userSet = false;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  user = this.user$.asObservable();

  private _api: string = environment.api;

  constructor(
    protected readonly http: HttpClient,
    protected readonly storage: StorageService,
    protected readonly toast: ToastService,
    protected readonly loading: LoadingService,
    protected readonly promoterService: PromoterService
  ) {
    super(http, storage, toast, loading);
  }

  init() {
    return this.getUserByToken();
  }

  async isLogged(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUser()
        .pipe(
          first(),
          map((user) => {
            return !!user?.id
          })
        )
        .subscribe((result) => {
          if (!!result) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
    });
  }

  getUserByToken(): Observable<User> {
    return from(this.storage.get('token', false))
      .pipe(
        switchMap((token: string) => {
          const headers = new HttpHeaders().append('Content-Type', 'application/json').set('Authorization', token);

          return this.get<UserDTO>('users/me?populate[0]=role&populate[1]=promoters&populate[2]=rrpp&populate[3]=promoters.defaultFee&populate[4]=promoters.teams&populate[5]=promoters.users&populate[6]=carts&populate[7]=rrpp.team_rrpps.team&populate[8]=rrpp.team_rrpps.team.promoter.leader&populate[9]=rrpp.teamsLeaded', null, headers)
            .pipe(
              switchMap((value: UserDTO) => {
                // const tokenUser: User = User.create(value);
                const tokenUser = UserDTO.forClient(value);

                if (!tokenUser) {
                  console.log('???', tokenUser)
                }

                if (tokenUser && tokenUser.role.name === 'Admin') {
                  return this.getAdminUserInfo(tokenUser)
                    .pipe(
                      switchMap((admin: User) => {
                        tokenUser.promoters = admin.promoters;

                        return from(this.setUser(tokenUser))
                          .pipe(
                            map(() => {
                              if (tokenUser && tokenUser.promoters?.length > 0) {
                                this.promoterService.setSelectedPromoter(tokenUser.promoters[0], tokenUser.promoters);
                              }

                              return tokenUser;
                            })
                          );
                      })
                    );
                } else {
                  return from(this.setUser(tokenUser))
                    .pipe(
                      map((res) => {
                        if (tokenUser && tokenUser.role.name === 'Promoter' && tokenUser.promoters?.length > 0) {
                          this.promoterService.setSelectedPromoter(tokenUser.promoters[0], tokenUser.promoters);
                        }

                        return tokenUser;
                      })
                    );
                }
              })
            );
        }),
        catchError((err) => {
          console.log('ERROR', err);
          this.storage.clear();
          return of(null);
        })
      );
  }

  getAdminUserInfo(admin: User): Observable<User> {
    return this.promoterService.getAllPromoters()
      .pipe(
        first(),
        map((promoters) => {
          admin.promoters = promoters;

          return admin;
        })
      );
  }

  login(email: string, password: string): Observable<User> {
    return this.post('auth/local?populate[0]=role&populate[1]=promoters&populate[2]=rrpp&populate[3]=rrpp.teamsLeaded', { identifier: email, password })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        switchMap((login: { user: UserDTO; jwt: string }) => {

          if (login && login.user) {
            const user = UserDTO.forClient(login.user);

            return this.setInStore('token', `Bearer ${login.jwt}`, false)
              .pipe(
                switchMap((store) => {
                  return this.setUser(user)
                    .pipe(
                      map((usr) => {

                        return user;
                      })
                    );
                })
              );
          } else {
            throw new Error();
          }
        }),
        map((results) => {
          return results;
        })
      );
  }

  register(body: RegisterDTO): Observable<User> {
    return this.post('auth/local/register', body)
      .pipe(
        map((res) => {
          if (res.user) {
            // this.toast.success('¡Enhorabuena! Se ha creado su cuenta. Por favor, revise su correo electrónico para confirmar su cuenta.', 10000);
            return UserDTO.forClient(res.user);
          } else {
            this.toast.error('Ha ocurrido un error.');
            return null;
          }
        })
      )
  }

  logout() {
    this.userSet = false;
    this.user$.next(null);
    this.promoterService.clear();
    return this.clearStorage();
  }

  setUser(user: User) {
    return this.setInStore('user', user, true)
      .pipe(
        first(),
        map(() => {
          this.userSet = true;
          this.user$.next(user);
          return;
        })
      );
  }

  getUser(): Observable<User> {
    return this.user;
  }

  mapUserMe(usr: any): User {
    const model = new User();

    model.id = usr.id;
    model.name = usr.name;
    model.lastName = usr.lastName;
    model.username = usr.username;
    model.email = usr.email;
    model.phone = usr.phone;
    model.dni = usr.dni;
    model.whatsapp = usr.whatsapp;

    if (usr.role) {
      model.role = new Role();

      model.role.id = usr.role.id;
      model.role.name = usr.role.name;
      model.role.description = usr.role.description;
      model.role.type = usr.role.type;
    }

    if (usr.promoters && usr.promoters.length) {
      model.promoters = usr.promoters.map((prom) => {
        const promoter = new Promoter();

        promoter.id = prom.id;
        promoter.name = prom.name;
        promoter.owners = prom.owners;
        promoter.defaultFee = prom.defaultFee;
        promoter.teams = prom.teams;
        promoter.events = prom.events;

        return promoter;
      });
    }

    model.rrpp = usr.rrpp;

    return model;
  }

  getProfile(): Observable<User> {
    return this.get<UserDTO>('profile')
      .pipe(
        map((profile) => UserDTO.forClient(profile))
      );
  }

  changePassword(body) {
    return this.post('auth/change-password', body);
  }

  forgotPassword(email: string) {
    return this.post('auth/forgot-password', { email });
  }

  confirmEmail(code: string) {
    return this.http.get(`${this._api}/auth/email-confirmation?confirmation=${code}`);
  }

  connectGoogle(accesToken: string) {
    return this.get('auth/google/callback?access_token=' + accesToken);
  }

  updateProfile(userId: number, body: any) {
    return this.put(`users/${userId}`, body);
  }

  resetPassword(body: any) {
    return this.post('auth/reset-password', body);
  }
}
