import { LoadingController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map, switchMap } from 'rxjs';
import { RegisterDTO } from 'src/app/services/auth/DTO/register.dto';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmPasswordValidator } from 'src/app/validators/confirm-password.validator';
import { DNIValidator } from 'src/app/validators/dni.validator';
import { PhoneValidator } from 'src/app/validators/phone.validator';
import { TeamService } from 'src/app/services/team/team.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Team } from 'src/app/models/user/team.model';
import slugify from '@sindresorhus/slugify';
import { RrppService } from 'src/app/services/rrpp/rrpp.service';
import { EmailEndValidator } from 'src/app/validators/email-end.validator';

@Component({
  selector: 'xpo-register-comp',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  loadingCtrl = inject(LoadingController);

  @Input() isModal = false;
  @Input() teamCode: string;

  @Output() onLoggin: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRegister: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;

  RRPPForm: FormGroup;

  showPassword = false;
  showRepassword = false;

  team: Team;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly teamService: TeamService,
    private readonly toastService: ToastService,
    private readonly rrppService: RrppService
  ) {
    this.form = new FormGroup({
      email: new FormControl<string>(null, [Validators.required, Validators.email]),
      password: new FormControl<string>(null, [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}')]),
      repassword: new FormControl<string>(null, [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}')]),
      name: new FormControl<string>(null, [Validators.required]),
      lastName: new FormControl<string>(null, [Validators.required]),
      phone: new FormControl<string>(null, [Validators.required]),
      dni: new FormControl<string>(null, [Validators.required]),
      whatsapp: new FormControl<boolean>(false, [Validators.required]),
      conditions: new FormControl<boolean>(false, [Validators.required, Validators.requiredTrue]),
    });

    this.form.get('repassword').addValidators(ConfirmPasswordValidator(this.form.get('password'), this.form.get('repassword')));

    this.form.get('email').addValidators(EmailEndValidator(this.form.get('email')));

    this.form.get('phone').addValidators(PhoneValidator(this.form.get('phone')));
    this.form.get('dni').addValidators(DNIValidator(this.form.get('dni')));
  }

  async ngOnInit() {
    if (this.teamCode) {
      const loading = await this.loadingCtrl.create({
        message: 'Comprobando código de equipo...',
      });

      await loading.present();

      this.teamService.getTeamByCode(this.teamCode)
        .pipe(
          first()
        )
        .subscribe({
          next: (team) => {
            if (team) {
              this.team = team;
              this.initRRPPForm();
              this.toastService.success(`Equipo válido. ${team.name} @ ${team.promoter.name}`);
            }
          },
          error: (error) => {
            this.toastService.error('El código de equipo no es válido');
          },
          complete: () => {
            loading.dismiss();
          }
        });
    }
  }

  register() {
    const body: RegisterDTO = this.getBody();

    this.authService.register(body)
      .pipe(
        first(),
        map((usr) => !!usr.id),
      )
      .subscribe((result: boolean) => {
        if (result === true) {
          this.toastService.success('Usuario registrado correctamente');

          if (!this.isModal) {
            this.router.navigate(['login']);
          }

          this.onRegister.emit(true);
        } else {
          this.toastService.error('Error al registrar usuario');

          this.form.reset();
        }
      });
  }

  registerRRPP() {
    const body: RegisterDTO = this.getBody();
    this.authService.register(body)
      .pipe(
        first(),
        switchMap((usr) => {
          if (usr.id) {
            return this.rrppService.registerRRPP({
              nickname: this.RRPPForm.get('nickname').value,
              code: this.RRPPForm.get('code').value,
              teamId: this.team.id,
              userId: usr.id
            });
          }
        }),
      )
      .subscribe({
        next: (result) => {
          if (result) {
            this.toastService.success('RRPP registrado correctamente');

            if (!this.isModal) {
              this.router.navigate(['login']);
            } else {
              this.onRegister.emit(true);
            }
          }
        },
        error: (error) => {
          this.toastService.error('Error al registrar el RRPP');
          if (this.isModal) {
            this.onRegister.emit(false);
          } else {
            this.router.navigate(['']);
          }
        }
      })
  }

  async initRRPPForm() {
    this.RRPPForm = new FormGroup({
      code: new FormControl<string>(null, [Validators.required]),
      nickname: new FormControl<string>(null, [Validators.required]),
      team: new FormControl<number>(this.team.id, [Validators.required]),
    });

    this.RRPPForm.get('code').disable();

    this.RRPPForm.get('nickname').valueChanges.subscribe(async (nickname) => {
      if (!nickname) {
        this.RRPPForm.get('code').reset();
      }

      await this.getSlug(slugify(nickname).toLowerCase());
    });
  }

  async getSlug(value: string) {
    let candidate = slugify(value);
    let i = 1;

    let done = false;

    while (!done) {
      if (i > 1) {
        candidate = slugify(value + '-' + i);
      }

      done = await this.rrppService.slugAvailable(candidate);
      i++;
    }

    this.RRPPForm.get('code').setValue(candidate);
  }

  goToLogin() {
    this.onLoggin.emit();
  }

  private getBody(): RegisterDTO {
    const email = this.form.get('email').value as string;
    const username = email.split('@')[0].trim().toLowerCase();

    return {
      username,
      email: this.form.get('email').value,
      password: this.form.get('password').value,
      name: this.form.get('name').value,
      lastName: this.form.get('lastName').value,
      phone: this.form.get('phone').value,
      dni: this.form.get('dni').value,
      whatsapp: this.form.get('whatsapp').value
    };
  }
}
