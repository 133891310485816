import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, from, map, switchMap } from 'rxjs';
import { PromoterDTO } from 'src/app/DTO/promoter.dto';
import { Promoter } from 'src/app/models';
import { XipOcioService } from '../service/service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../service/storage.service';
import { ToastService } from '../toast/toast.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class PromoterService extends XipOcioService {
  selectedPromoter: Promoter;
  selectedPromoter$: ReplaySubject<Promoter> = new ReplaySubject(null);

  availablePromoters: Promoter[] = [];
  availablePromoters$: ReplaySubject<Promoter[]> = new ReplaySubject(null);

  constructor(
    protected readonly http: HttpClient,
    protected readonly storage: StorageService,
    protected readonly toast: ToastService,
    protected readonly loading: LoadingService
  ) {
    super(http, storage, toast, loading);
  }

  setSelectedPromoter(promoter: Promoter, availables?: Promoter[]) {
    if (availables) {
      this.availablePromoters = availables;
      this.updateAvailablePromoters();
    }

    const foundPromoter = this.availablePromoters.find((prom) => prom.id === promoter.id);

    this.selectedPromoter = foundPromoter || null;

    this.updateSelectedPromoter();
  }

  getAllPromoters(): Observable<Promoter[]> {
    return this.get<PromoterDTO[]>('promoters/admin')
      .pipe(
        map((response: PromoterDTO[]) => {
          const promoters = response?.map((prom) => PromoterDTO.forClient(prom));

          this.availablePromoters = promoters;
          this.updateAvailablePromoters();

          return promoters;
        })
      );
  }

  getMyPromoters(): Observable<Promoter[]> {
    return from(this.storage.get('user', true))
      .pipe(
        switchMap((user) => {
          return this.get<PromoterDTO[]>(`promoters?populate[0]=defaultFee&populate[1]=teams.leader&populate[2]=users&populate[3]=teams.leaderFee&populate[4]=teams.team_rrpps.rrpp&populate[5]=events.image&populate[6]=events.banner&populate[7]=events.categories&populate[8]=events.genres&populate[9]=events.place.population&populate[10]=events.event_days.ticket_variations.fee&populate[11]=events.promoters&filters[owners][id]=${user.id}`)
            .pipe(
              map((response: PromoterDTO[]) => {
                const promoters = response?.map((prom) => PromoterDTO.forClient(prom));

                this.availablePromoters = promoters;
                this.updateAvailablePromoters();

                return promoters;
              })
            );
        })
      );
  }

  private updateSelectedPromoter() {
    this.selectedPromoter$.next(this.selectedPromoter);
  }

  private updateAvailablePromoters() {
    this.availablePromoters$.next(this.availablePromoters);
  }

  clear() {
    this.selectedPromoter = null;
  }
}
