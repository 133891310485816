import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private readonly storage: Storage,
    private readonly cookieService: CookieService
  ) { }

  async init() {
    await this.storage.create();


  }

  async get(key: string, parsed: boolean) {
    const stValue = await this.storage.get(key);

    if (stValue) {
      return stValue;
    }

    const value = this.cookieService.get(key);

    if (!value) {
      return null;
    }

    return parsed ? JSON.parse(value) : value;
  }

  async set(key: string, value: any, parse: boolean) {
    await this.storage.set(key, value);
    const val = parse ? JSON.stringify(value) : value;

    return this.cookieService.put(key, val);
  }

  async remove(key: string) {
    await this.storage.remove(key);
    return this.cookieService.remove(key);
  }

  async clear() {
    await this.storage.clear();
    return this.cookieService.removeAll()
  }
}
