import { Place } from '../models';
import { AddressDTO } from './address.dto';
import { PopulationDTO } from './population.dto';

export class PlaceDTO {
  name: string;
  population?: PopulationDTO;
  address: AddressDTO;
  locationY?: string;
  locationX?: string;

  static forClient(entity: any): Place {
    const model = new Place();

    model.id = entity.id;
    model.name = entity.name;
    model.locationX = entity.locationX;
    model.locationY = entity.locationY;

    if (entity.population) {
      model.population = PopulationDTO.forClient(entity.population);
    } else {
      model.population = null;
    }

    if (entity.address) {
      model.address = AddressDTO.forClient(entity.address);
    } else {
      model.address = null;
    }

    return model;
  }
}
