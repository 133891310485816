export class Genre {
  id: number;
  name: string;
  tag: string;

  static create(genre: Partial<Genre>): Genre {
    const model = new Genre();

    model.id = genre.id;
    model.name = genre.name;
    model.tag = genre.tag;

    return model;
  }
}
