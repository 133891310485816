import { Event } from '../event';
import { Fee } from '../generic/fee.model';
import { Team } from './team.model';
import { User } from './user.model';

export class Promoter {
  id: number;
  name: string;
  owners?: User[];
  defaultFee?: Fee;
  teams?: Team[];
  events?: Event[];

  static create(promoter: Partial<Promoter>): Promoter {
    const model = new Promoter();

    model.id = promoter.id;
    model.name = promoter.name;

    if (promoter.owners && promoter.owners.length) {
      model.owners = promoter.owners.map((owner) => {
        return User.create(owner);
      });
    }

    if (promoter.defaultFee) {
      model.defaultFee = Fee.create(promoter.defaultFee);
    }

    if (model.teams && model.teams.length) {
      model.teams = promoter.teams.map((team) => {
        return Team.create(team);
      });
    }

    model.events = promoter.events;

    return model;
  }
}
