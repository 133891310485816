import { AbstractControl } from "@angular/forms";

export function EmailEndValidator(control: AbstractControl) {
  return () => {
    if (!control.value) {
      return null;
    }

    if (control.value.endsWith('.con') || control.value.endsWith('.co')) {
      return { emailEnd: true };

    } else {
      return null;
    }
  };
}
