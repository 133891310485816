import { VariationAddOn } from "../models/event/variation-addon.model";
import { AddOnDTO } from "./addon.dto";
import { TicketVariationDTO } from "./ticket-variation.dto";

export class VariationAddOnDTO {
  id: number;
  price: number;
  onePerTicket: boolean;
  mandatory: boolean;
  ticket_variation?: TicketVariationDTO;
  add_on?: AddOnDTO;

  static forClient(entity: VariationAddOnDTO): VariationAddOn {
    const model = new VariationAddOn();

    model.id = entity.id;
    model.price = entity.price;
    model.onePerTicket = entity.onePerTicket;
    model.ticketVariation = entity.ticket_variation ? TicketVariationDTO.forClient(entity.ticket_variation) : null;
    model.addOn = entity.add_on ? AddOnDTO.forClient(entity.add_on) : null;
    model.mandatory = entity.mandatory;

    return model;
  }

}