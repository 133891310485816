import { CartItem } from 'src/app/models';
import { RRPPDTO } from './rrpp.dto';
import { CartDTO } from './cart.dto';
import { TicketVariationDTO } from './ticket-variation.dto';
import { VariationAddOnDTO } from './variation-addon.dto';
export class CartItemDTO {
  id: number;
  quantity: number;
  ticket_variation: TicketVariationDTO;
  rrpp?: RRPPDTO;
  cart?: CartDTO;
  variation_add_ons?: VariationAddOnDTO[];

  static forClient(entity: CartItemDTO): CartItem {
    const model = new CartItem();

    model.id = entity.id;
    model.quantity = entity.quantity;
    model.variation = TicketVariationDTO.forClient(entity.ticket_variation);

    model.event = model.variation.event;

    if (entity.rrpp) {
      model.rrpp = RRPPDTO.forClient(entity.rrpp);
    }

    if (entity.cart) {
      model.cart = CartDTO.forClient(entity.cart);
    }

    if (entity.variation_add_ons) {
      model.variationAddons = entity.variation_add_ons.map(x => VariationAddOnDTO.forClient(x));
    }

    return model;
  }
}
