import { Injectable, inject } from '@angular/core';
import { Loading, LoadingType } from './DTO/loading.model';
import { Observable, ReplaySubject, map } from 'rxjs';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private currentLoadings: Loading[] = [];
  loadings: ReplaySubject<Loading[]> = new ReplaySubject<Loading[]>();

  private loadingCtrl = inject(LoadingController);

  constructor() { }

  init(loading: boolean = true, type: LoadingType = LoadingType.global, message?: string): Loading {
    const load = new Loading(loading, type, message);

    this.currentLoadings.push(load);
    this.updateLoadings();

    return load;
  }

  async setLoadingTrue(load: Loading) {
    const loading = this.currentLoadings.find((l) => l.id === load.id);

    if (!loading) {
      return;
    }

    loading.loading = true;

    if (loading.type === LoadingType.toast) {
      await this.initToastLoading(loading);
    }

    this.updateLoadings();
  }

  async setLoadingFalse(load: Loading) {
    const loading = this.currentLoadings.find((l) => l.id === load.id);

    if (!loading) {
      return;
    }

    loading.loading = false;

    if (loading.type === LoadingType.toast) {
      await this.dimissToastLoading(loading);
    }

    this.updateLoadings();
  }

  async setLoadingTrueById(id: string) {
    const loading = this.currentLoadings.find((l) => l.id === id);

    if (!loading) {
      return;
    }

    loading.loading = true;

    if (loading.type === LoadingType.toast) {
      await this.initToastLoading(loading);
    }

    this.updateLoadings();
  }

  async setLoadingFalseById(id: string) {
    const loading = this.currentLoadings.find((l) => l.id === id);

    if (!loading) {
      return;
    }

    loading.loading = false;

    if (loading.type === LoadingType.toast) {
      await this.dimissToastLoading(loading);
    }

    this.updateLoadings();
  }

  get allLoadings(): Observable<Loading[]> {
    return this.loadings;
  }

  get globalLoadings(): Observable<Loading[]> {
    return this.loadings
      .pipe(
        map(
          (loadings) => {
            return loadings.filter((loading) => (loading.type === LoadingType.global && loading.loading === true));
          }
        )
      );
  }

  get toastLoadings(): Observable<Loading[]> {
    return this.loadings
      .pipe(
        map(
          (loadings) => {
            return loadings.filter((loading) => (loading.type === LoadingType.toast && loading.loading === true));
          }
        )
      );
  }

  private async initToastLoading(loading: Loading) {
    const loadingToast = await this.loadingCtrl.create({
      message: loading.messate ?? 'Cargando...',
      backdropDismiss: false,
      showBackdrop: true,
    });

    loading.toastEl = loadingToast;

    await loadingToast.present();
  }

  private async dimissToastLoading(loading: Loading) {
    await this.loadingCtrl.dismiss(null);

    // await loading.toastEl.dismiss();
  }

  private updateLoadings() {
    this.loadings.next(this.currentLoadings);
  }
}
