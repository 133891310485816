import { User } from '../models';
import { PromoterDTO } from './promoter.dto';
import { RoleDTO } from './role.dto';
import { RRPPDTO } from './rrpp.dto';

export class UserDTO {
  id: number;
  name: string;
  lastName: string;
  username: string;
  email: string;
  role: RoleDTO;
  phone: string;
  dni: string;
  whatsapp: boolean;
  promoters?: PromoterDTO[];
  rrpp?: RRPPDTO;

  static forClient(entity: UserDTO): User {
    const model = new User();

    model.id = entity.id;
    model.name = entity.name;
    model.lastName = entity.lastName;
    model.username = entity.username;
    model.email = entity.email;
    model.phone = entity.phone;
    model.dni = entity.dni;
    model.whatsapp = entity.whatsapp;

    if (entity.role) {
      model.role = RoleDTO.forClient(entity.role as RoleDTO);
    }

    if (entity.promoters) {
      model.promoters = entity.promoters.map((prom: PromoterDTO) => PromoterDTO.forClient(prom));
    }

    if (entity.rrpp) {
      model.rrpp = RRPPDTO.forClient(entity.rrpp);
    }

    return model;
  }
}
