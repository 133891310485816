import { RequestStatus } from "src/app/DTO/request.dto";
import { User } from "../user";
import { RRPPRequest } from "./rrpp-request.model";

export class Request {
  id: number;
  status: RequestStatus;
  user: User;
  comment: string;
  eventRequest: any;
  rrppRequest: RRPPRequest;
  sent: boolean;
}
