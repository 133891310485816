import { TeamRRPP } from './TeamRRPP.model';
import { Team } from './team.model';
import { User } from './user.model';

export class RRPP {
  id: number;
  nickname: string;
  code: string;
  active: boolean;
  user?: User;
  teamRRPPs?: TeamRRPP[];
  teamsLeaded?: Team[];

  static create(rrpp: Partial<RRPP>): RRPP {
    const model = new RRPP();

    model.id = rrpp.id;
    model.nickname = rrpp.nickname;
    model.active = rrpp.active;
    model.code = rrpp.code;

    if (rrpp.user) {
      model.user = User.create(rrpp.user);
    }

    if (rrpp.teamRRPPs && rrpp.teamRRPPs.length) {
      model.teamRRPPs = rrpp.teamRRPPs.map((teamRrpp) => {
        return TeamRRPP.create(teamRrpp);
      });
    }

    return model;
  }
}
