import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Devices } from 'src/app/models/generic/devices.enum';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  width: ReplaySubject<string> = new ReplaySubject<string>();
  height: ReplaySubject<string> = new ReplaySubject<string>();
  device: ReplaySubject<Devices> = new ReplaySubject<Devices>();

  private deviceWidth: number;
  private deviceHeight: number;

  constructor() { }

  update(width: number, height: number) {
    this.deviceWidth = width;
    this.deviceHeight = height;

    this.width.next(`${this.deviceWidth}px`);
    this.height.next(`${this.deviceHeight}px`);
    this.device.next(this.getDevice());
  }

  private getDevice() {
    // CHECK VARIABLES.SCSS + 1px
    // $phone: 576px;
    // $tablet: 768px;
    // $laptop: 992px;
    // $desktop: 1200px;

    if (this.deviceWidth <= 577) {
      return Devices.phone;
    } else if (this.deviceWidth > 577 && this.deviceWidth <= 769) {
      return Devices.tablet;
    } else if (this.deviceWidth > 769 && this.deviceWidth <= 993) {
      return Devices.laptop;
    } else {
      return Devices.desktop;
    }
  }
}
