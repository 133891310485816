import { Event, TicketVariation } from "../event";
import { Ticket } from "../ticket";
import { EventRRPPStats, RRPPTicketSalesStats } from "./event-rrpp-stats";
import { VariationStats } from "./variation-stats";

export class EventStats {
  id: number;
  name: string;
  sales: number;
  orders: number;
  tickets: number;
  pax: number;
  // gg: number;
  taxes: number;
  guests: number;
  generated: number;
  totalStock: null | number;
  allVariationsHasStock: boolean;
  totalStockCalculated: null | number;

  variations?: Map<number, VariationStats>;
  rrppStats: Map<number, EventRRPPStats>;

  chart: any;

  static fromAPI(event: Event): EventStats {
    const model = new EventStats();

    model.id = event.id;
    model.name = event.name;

    model.tickets = 0;
    model.pax = 0;
    model.orders = 0;
    model.sales = 0;
    // model.gg = 0;
    model.taxes = 0;
    model.guests = 0;
    model.generated = 0;
    model.totalStock = 0;
    model.totalStockCalculated = 0;
    model.variations = new Map<number, VariationStats>();
    model.rrppStats = new Map<number, EventRRPPStats>();

    let orders: number[] = [];

    model.allVariationsHasStock = event.ticketVariations?.every((variation) => !!variation.stock?.limit);

    event.ticketVariations?.forEach((variation) => {
      const validTickets = this.getValidTickets(variation.tickets);

      model.tickets += validTickets.filter((tk) => !tk.generation?.id).length;

      model.pax += validTickets.length * variation.pax;
      model.generated += validTickets.filter((tk) => tk.generation?.id && tk.generation?.status === 'Approved').length;

      if (variation.stock?.limit) {
        model.totalStock += variation.stock?.limit ?? 0;
      } else {
        model.totalStockCalculated += validTickets.length;
      }

      validTickets.forEach((vt) => {
        if (!vt.generation?.id) {
          model.sales += vt.orderItem?.unitPrice ?? 0;
        }

        // model.gg += vt.orderItem.quantity * vt.orderItem.unitFee;

        if (!orders.includes(vt.orderItem.order.id)) {
          orders.push(vt.orderItem.order.id);
        }

        if (vt.orderItem.rrpp?.id) {
          model.taxes += vt.orderItem.unitRrppFee;
        }
      });

      const variationStats = VariationStats.getVariationStats(variation);
      model.variations.set(variation.id, variationStats);

      validTickets.forEach((tk) => {
        let rrppTicketStats;

        if (tk.orderItem.rrpp) {
          if (model.rrppStats.has(tk.orderItem.rrpp.id)) {
            const prevRRPPStats = model.rrppStats.get(tk.orderItem.rrpp.id);

            prevRRPPStats.tickets++;
            prevRRPPStats.sales += tk.orderItem?.unitPrice ?? 0;
            prevRRPPStats.tax += tk.orderItem.unitRrppFee;


            rrppTicketStats = prevRRPPStats.ticketSales;

          } else {
            const rrppStat: EventRRPPStats = {
              name: tk.orderItem.rrpp.nickname,
              tickets: 1,
              sales: tk.orderItem?.unitPrice ?? 0,
              tax: tk.orderItem.unitRrppFee,
              ticketSales: new Map<string, RRPPTicketSalesStats>()
            };

            model.rrppStats.set(tk.orderItem.rrpp.id, rrppStat);

            rrppTicketStats = rrppStat.ticketSales;
          }

          if (rrppTicketStats.has(variation.name)) {
            const prevTkStats = rrppTicketStats.get(variation.name);

            prevTkStats.tickets++;
            prevTkStats.sales += tk.orderItem?.unitPrice ?? 0;
            prevTkStats.tax += tk.orderItem.unitRrppFee;

          } else {
            const rrppTkStats: RRPPTicketSalesStats = {
              name: variation.name,
              tickets: 1,
              sales: tk.orderItem?.unitPrice ?? 0,
              tax: tk.orderItem.unitRrppFee
            };

            rrppTicketStats.set(variation.name, rrppTkStats);
          }
        }
      })
    });

    if (!model.allVariationsHasStock) {
      let stockedTickets = 0;
      let stockedLimit = 0;
      let noStockedTickets = 0;

      model.variations.forEach((vari) => {
        if (!vari.infinite) {
          stockedTickets += vari.tickets;
          stockedLimit += vari.stock;
        } else {
          noStockedTickets += vari.tickets;
        }
      });

      const percent = (stockedTickets / stockedLimit * 100);

      const calculated = (stockedTickets + noStockedTickets) / (percent / 100);

      model.totalStockCalculated = calculated;
    }

    model.orders += orders.length;

    // day.guestLists.forEach((gl) => {
    //   const guest = gl.items.filter((item) => item.status.toString() !== 'Cancelled').length;
    //   model.guests += guest;
    //   model.pax += guest;
    // })

    model.chart = this.getDoughnutLabelsAndDatasets(model);

    return model;
  }

  static getValidTickets(tickets: Ticket[]): Ticket[] {
    return tickets.filter((tk) => {
      if (!tk.code || tk.code === null) {
        return false;
      } else if (['Cancelled', 'Refunded', 'Unpaid'].includes(tk.status.toString())) {
        return false;
      }

      return true;
    });
  }

  static getDoughnutLabelsAndDatasets(model: EventStats) {

    const labels: string[] = [];
    const availableColors = [
      '#f6a91c',
      '#FC9E4F',
      '#EDD382',
      '#AC8B14',
      '#f6f21c',
      '#f69b1c',
      '#ac6c14'
    ];

    let dataset: {
      data: number[],
      backgroundColor: string[]
    } = {
      data: [],
      backgroundColor: []
    };

    let index = 0;

    model.variations.forEach((vari) => {
      labels.push(vari.name);

      dataset.data.push(vari.tickets);
      dataset.backgroundColor.push(availableColors[index]);
      index++;
    });

    labels.push('Invitados');
    dataset.data.push(model.guests);
    dataset.backgroundColor.push('#F4442E');

    const sold = dataset.data.reduce((a, b) => a + b);
    let unsold = 0;

    if (model.allVariationsHasStock) {
      unsold = model.totalStock - sold;
    } else {
      let stockedTickets = 0;
      let stockedLimit = 0;
      let noStockedTickets = 0;

      model.variations.forEach((vari) => {
        if (!vari.infinite) {
          stockedTickets += vari.tickets;
          stockedLimit += vari.stock;
        } else {
          noStockedTickets += vari.tickets;
        }
      });

      unsold = stockedLimit - stockedTickets;
    }

    // labels.push('Generadas');
    // dataset.data.push(model.generated);
    // dataset.backgroundColor.push('#7a1103');

    labels.push('Restante');

    dataset.data.push(unsold);

    dataset.backgroundColor.push('#e5e5e5');

    return {
      labels,
      datasets: [
        dataset
      ]
    };
  }
}

