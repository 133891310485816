import { Ticket } from '../ticket';
import { Promoter } from './promoter.model';
import { Role } from './role.model';
import { RRPP } from './RRPP.model';

export class User {
  id: number;
  name: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  dni: string;
  whatsapp: boolean;
  role: Role;
  promoters?: Promoter[];
  rrpp?: RRPP;
  tickets?: Ticket[];

  static create(user: Partial<User>): User {
    const model = new User();

    model.id = user.id;
    model.name = user.name;
    model.lastName = user.lastName;
    model.username = user.username;
    model.email = user.email;
    model.phone = user.phone;
    model.dni = user.dni;
    model.whatsapp = user.whatsapp;
    model.role = Role.create(user.role);

    if (user.promoters && user.promoters.length) {
      model.promoters = user.promoters.map((prom) => {
        return Promoter.create(prom);
      });
    }

    if (user.rrpp) {
      model.rrpp = RRPP.create(user.rrpp);
    }

    if (user.tickets) {
      model.tickets = user.tickets.map((ticket) => {
        return Ticket.create(ticket);
      });
    }

    return model;
  }
}
