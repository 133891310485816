import { AddOn } from "./addon.model";
import { TicketVariation } from "./ticketVariation.model";

export class VariationAddOn {
  id: number;
  price: number;
  onePerTicket: boolean;
  mandatory: boolean;
  ticketVariation?: TicketVariation;
  addOn?: AddOn;
}