import { AbstractControl, ValidatorFn } from "@angular/forms";
import { isValid, normalize } from "better-dni";

export function DNIValidator(control: AbstractControl<string>): ValidatorFn {
  return () => {
    if (!control.value) {
      return null;
    }

    const regexESP = new RegExp('^[0-9]{8}[A-Z]$').test(control.value);
    const regexPT = new RegExp(/^\d{8}[- ]?\d[ ]?[A-Za-z]{2}\d$/).test(control.value);


    if (!regexESP && !regexPT) {
      return { dni: true };
    }

    const normalized = normalize(control.value);

    if (isValid(normalized) || regexESP || regexPT) {
      return null;
    } else {
      return { dni: true };
    }
  };
}
