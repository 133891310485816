import { TicketStatus } from './ticket.status.enum';
import { OrderItem } from "../order";
import { RRPP, User } from "../user";
import { Attendee } from "./attendee.model";
import { TicketVariation } from '../event';
import { TicketGeneration } from './ticket-generation.model';
import { VariationAddOn } from '../event/variation-addon.model';

export class Ticket {
  id: number;
  code: string;
  status: TicketStatus;
  attendees: Attendee[];
  RRPP?: RRPP;
  orderItem?: OrderItem;
  user?: User;
  ticketVariation?: TicketVariation;
  generation: TicketGeneration;
  variationAddons: VariationAddOn[];

  static create(ticket: Partial<Ticket>): Ticket {
    const model = new Ticket();
    model.id = ticket.id;
    model.code = ticket.code;
    model.status = ticket.status;

    if (ticket.attendees) {
      model.attendees = ticket.attendees.map((att) => {
        return Attendee.create(att);
      });
    }

    if (ticket.RRPP) {
      model.RRPP = RRPP.create(ticket.RRPP);
    }

    if (ticket.orderItem) {
      model.orderItem = OrderItem.create(ticket.orderItem);
    }

    if (ticket.user) {
      model.user = User.create(ticket.user);
    }

    return model;
  }
}
