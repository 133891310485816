import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, from, lastValueFrom, switchMap, throwError, } from 'rxjs';
import { StorageService } from '../services/service/storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private readonly storage: StorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(
      this.handle(request, next)
    );
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await this.storage.get('token', false);

    if (token) {
      const clone = req.clone({
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          'Authorization': token
        })
      });
      return await lastValueFrom(
        next.handle(clone)
          .pipe(
            catchError((error) => {
              return throwError(() => error);
            })
          )
      );
    } else {
      return await lastValueFrom(
        next.handle(req)
          .pipe(
            catchError((error) => {
              return throwError(() => error);
            })
          )
      );
    }
  }
}
