import { Order } from './order.model';
import { TicketVariation } from "../event";
import { Ticket } from "../ticket";
import { RRPP } from '../user';
import { VariationAddOn } from '../event/variation-addon.model';

export class OrderItem {
  id: number;
  quantity: number;
  unitPrice: number;
  unitFee: number;
  unitRrppFee: number;
  unitAddon: number;
  variation: TicketVariation;
  tickets?: Ticket[];
  order?: Order;
  rrpp?: RRPP;
  variationAddons: VariationAddOn[];

  constructor() { }

  get subtotal(): number {
    return this.quantity * this.unitPrice;
  }

  get subtotalWithTaxes(): number {
    return this.quantity * (this.unitPrice + this.unitFee);
  }

  static create(item: Partial<OrderItem>): OrderItem {
    const model = new OrderItem();

    model.id = item.id;
    model.quantity = item.quantity;
    model.unitPrice = item.unitPrice;
    model.unitFee = item.unitFee;

    if (item.variation) {
      model.variation = TicketVariation.create(item.variation);
    }

    if (item.tickets) {
      model.tickets = item.tickets.map((tk) => {
        return Ticket.create(tk);
      });
    }

    if (item.order) {
      model.order = Order.create(item.order);
    }

    if (item.rrpp) {
      model.rrpp = RRPP.create(item.rrpp);
    }

    return model;
  }
}