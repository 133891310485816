import { OrderItem } from "../order";
import { Stock } from "./stock.model";

export class StockLine {
  id: number;
  blockDate: Date;
  buyDate: Date;
  orderItem: OrderItem;
  stock?: Stock;
}
