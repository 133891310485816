import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LoadingController, ModalController } from '@ionic/angular';
import { first } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ConfirmPasswordValidator } from 'src/app/validators/confirm-password.validator';

@Component({
  selector: 'xpo-change-password',
  templateUrl: 'change-password.html',
})
export class ChangePasswordComponent {
  private modalCtrl = inject(ModalController);
  private loadingCtrl = inject(LoadingController);
  private router = inject(Router);

  redirectOnCancel = true;
  form: FormGroup;

  showCurrent = false;
  showPassword = false;
  showRepassword = false;

  constructor(
    private readonly auth: AuthService,
    private readonly toast: ToastService
  ) {
    this.form = new FormGroup({
      currentPassword: new FormControl<string>(null, [Validators.required]),
      password: new FormControl<string>(null, [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}')]),
      passwordConfirmation: new FormControl<string>(null, [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}')]),
    });

    this.form.get('passwordConfirmation').addValidators(ConfirmPasswordValidator(this.form.get('password'), this.form.get('passwordConfirmation')));
  }

  async send() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      backdropDismiss: false,
      showBackdrop: true,
    });

    await loading.present();

    this.auth.changePassword(this.form.value)
      .pipe(
        first()
      )
      .subscribe({
        next: async (response) => {
          await loading.dismiss();
          await this.toast.success('Contraseña cambiada');
          await this.cancel();
        },
        error: async (err) => {
          await loading.dismiss();
          await this.toast.error('Se ha producido un error');
          await this.cancel();
        }
      })
  }

  cancel() {
    if (this.redirectOnCancel) {
      this.modalCtrl.dismiss(null, 'cancel');
      return this.router.navigate(['login']);
    } else {
      return this.modalCtrl.dismiss(null, 'cancel');
    }
  }
}