import { Genre } from './genre.model';
export class Category {
  id: number;
  name: string;
  tag: string;
  genres: Genre[];

  static create(cat: Partial<Category>): Category {
    const model = new Category();

    model.id = cat.id;
    model.name = cat.name;
    model.tag = cat.tag;

    if (cat.genres) {
      model.genres = cat.genres.map((gen) => {
        return Genre.create(gen);
      });
    }

    return model;
  }
}
