export class Fee {
  id: number;
  name: string;
  value: number;
  type: FeeType;
  zone: FeeZone;

  static create(fee: Partial<Fee>): Fee {
    const model = new Fee();

    model.id = fee.id;
    model.name = fee.name;
    model.value = fee.value;
    model.type = fee.type as FeeType;
    model.zone = fee.zone as FeeZone;

    return model;
  }

  calculate(price: number): number {
    if (this.type === FeeType.FIXED) {
      
      return price + this.value;
    } else {

      return price + ((price * this.value) / 100);
    }
  }

  valueStr(): string {
    if (this.type === FeeType.FIXED) {

      return this.value + '€';
    } else {

      return this.value + '%';
    }
  }
}

export enum FeeType {
  FIXED = 'Fixed',
  PERCENT = 'Percent'
}

export enum FeeZone {
  TAX = 'Tax',
  STAFF = 'Staff'
}
