import { Category, Genre } from '../generic';
import { Image } from '../generic/image.model';
import { Promoter } from '../user';
import { EventStatus } from './event-status.enum';
import { Place } from './place.model';
import { Stock } from './stock.model';
import { TicketVariation } from './ticketVariation.model';

export class Event {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: EventStatus;
  image: Image;
  categories: Category[];
  genres: Genre[];
  place: Place;
  promoters: Promoter[];
  adultRequired: boolean;
  uniqueTicket: boolean;
  dniRequired: boolean;
  featured: boolean;
  hasGenerations: boolean;
  hasGuestLists: boolean;
  publishDate: Date;
  sellDate: Date;
  stopDate: Date;
  startDate: Date;
  endDate: Date;
  stock: Stock;
  ticketVariations: TicketVariation[];
  scannerToken?: string;

  static create(event: Partial<Event>): Event {
    const model = new Event();

    model.id = event.id;
    model.name = event.name;
    model.slug = event.slug;
    model.description = event.description;
    model.status = event.status ?? EventStatus.INPROCESS;
    model.image = event.image;
    model.adultRequired = event.adultRequired;
    model.uniqueTicket = event.uniqueTicket;
    model.dniRequired = event.dniRequired;
    model.featured = event.featured;
    model.publishDate = event.publishDate;
    model.hasGenerations = event.hasGenerations;
    model.hasGuestLists = event.hasGuestLists;
    model.sellDate = event.sellDate;
    model.stopDate = event.stopDate;
    model.startDate = event.startDate;
    model.endDate = event.endDate;
    model.stock = event.stock;

    if (event.ticketVariations) {
      model.ticketVariations = event.ticketVariations.map((tv) => {
        return TicketVariation.create(tv);
      });
    }

    if (event.categories) {
      model.categories = event.categories.map((cat) => {
        return Category.create(cat);
      });
    }

    if (event.genres) {
      model.genres = event.genres.map((gen) => {
        return Genre.create(gen);
      });
    }

    if (event.place) {
      model.place = Place.create(event.place);
    }

    if (event.promoters) {
      model.promoters = event.promoters.map((prom) => {
        return Promoter.create(prom);
      });
    }

    return model;
  }
}
