import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '../components/header/header.component';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { QuantitySelectorModule } from '../components/quantity-selector/quantity-selector.module';
import { ChangePasswordComponent } from '../components/change-password/change-password';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';
import { LoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';

const COMPONENTS = [
  HeaderComponent,
  ImageUploadComponent,
  ChangePasswordComponent,
  LoginModalComponent,
  LoginComponent,
  RegisterComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    QuantitySelectorModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class ComponentsModule { }
