import { Role } from '../models/user/role.model';

export class RoleDTO {
  id: number;
  name: string;
  description: string;
  type: string;

  static forClient(entity: RoleDTO): Role {
    const model = new Role();

    model.id = entity.id;
    model.name = entity.name;
    model.description = entity.description;
    model.type = entity.type;

    return model;
  }
}

