import { inject } from "@angular/core";
import { CanActivateFn, UrlTree, Router } from "@angular/router";
import { Observable, firstValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import { ToastService } from "src/app/services/toast/toast.service";

const AUTHORIZED_ROLES = ['RRPP', 'Promoter', 'Admin'];

export const RRPPRoleGuard: CanActivateFn = (route, state): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const toast = inject(ToastService);

  return firstValueFrom(authService.getUser())
    .then((user) => {
      if (!user?.id) {
        return firstValueFrom(authService.init())
          .then((init) => {
            if (!init.id) {
              toast.error('No autorizado');
              router.navigate(['']);
              return false;
            }

            if (!AUTHORIZED_ROLES.includes(init.role.name)) {
              toast.error('No autorizado');
              router.navigate(['']);
              return false;
            }

            return true;
          })
          .catch((err2) => {
            toast.error('No autorizado');
            router.navigate(['']);
            return false;
          });
      }

      if (!AUTHORIZED_ROLES.includes(user.role.name)) {
        toast.error('No autorizado');
        router.navigate(['']);
        return false;
      }

      return true;
    })
    .catch((err) => {
      return firstValueFrom(authService.init())
        .then((init) => {
          if (!init.id) {
            return false;
          }

          if (!AUTHORIZED_ROLES.includes(init.role.name)) {
            toast.error('No autorizado');
            router.navigate(['']);
            return false;
          }

          return true;
        })
        .catch((err2) => {
          toast.error('No autorizado');
          router.navigate(['']);
          return false;
        });
    });
};
