export enum StatsTime {
  FIFTEENMIN = 900000,
  THIRTYMIN = 1800000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  LAST30DAYS = 2592000000,
  MONTH = 2592000000,
  YEAR = 31536000000,
  FULL = 0,
}

export enum StatsTimeMilliseconds {
  FIFTEENMIN = 900000,
  THIRTYMIN = 1800000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  LAST30DAYS = 2592000000,
  MONTH = 2592000000,
  YEAR = 31536000000,
  FULL = 0,
}
