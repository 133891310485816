import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { StorageService } from '../services/service/storage.service';
import { Observable, catchError, first, map, of, switchMap } from 'rxjs';
import { User } from '../models';
import { DeviceService } from '../services/device/device.service';
import { CartService } from '../services/cart/cart.service';
import { SessionService } from '../services/session/session.service';
import { Title } from '@angular/platform-browser';
import { Cart } from '../models/order/cart.model';

@Component({
  selector: 'xpo-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loaded = false;

  constructor(
    private readonly storage: StorageService,
    private readonly auth: AuthService,
    private readonly deviceService: DeviceService,
    private readonly cartservice: CartService,
    private readonly sessionService: SessionService,
    private readonly titleService: Title
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.deviceService.update(width, height);
  }

  async ngOnInit() {
    this.titleService.setTitle('XipOcio | Venta de entradas');
    await this.storage.init();
    const token = await this.storage.get('token', false);

    if (token) {
      this.getUser()
        .pipe(
          first(),
          switchMap((usr) => {
            if (!usr) {
              this.loaded = true;
              return of(null);
            }

            this.loaded = true;
            return this.cartservice.getPreviousCart()
              .pipe(
                first()
              )
          })
        )
        .subscribe({
          next: (cart: Cart) => {
            if (cart) {
              this.cartservice.setCart(cart);
            }
          },
          error: (err) => {
            console.log('EN ERR', err)
            this.storage.clear();
          },
          complete: () => {
            this.loaded = true;
          }
        });
    } else {
      this.cartservice.getPreviousCart()
        .pipe(
          first()
        )
        .subscribe((cart) => {
          if (cart) {
            this.cartservice.setCart(cart);
          }

          this.loaded = true;
        });
    }
  }

  private getUser(): Observable<User> {
    return this.auth.init()
      .pipe(
        map((usr) => {
          return usr;
        }),
        catchError((err) => {
          return of(null);
        })
      );
  }
}
