import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(date: any): unknown {
    return date ? format(parseISO(date), 'HH:mm', {
      locale: es
    }) : '';
  }

}
