export const environment = {
  production: true,
  front: "https://xipocio.com",
  api: "https://backoffice.xipocio.com/api",
  queue: 'https://queue.xipocio.com',
  redsysForm: "https://sis.redsys.es/sis/NC/redsysV3.js",
  redsysInit: 'https://sis.redsys.es/sis/rest/iniciaPeticionREST',
  resdysTrata: 'https://sis.redsys.es/sis/rest/trataPeticionREST',
  redsysBizum: 'https://sis.redsys.es/sis/realizarPago',
  redsysFuc: '353568975',
  redsysTerminal: '1',
  redsysMerchantKey: 'cDIzIxzVOLJQyLrZbHoO6va+9PPkGZAb',
  RECAPTCHA_V3_SITE_KEY: "6LeyyBAeAAAAAP92n0Uc-IxWh7RBx20F-b4vAIl0",
};
