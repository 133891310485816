import { OrderItem } from './../models/order/orderItem.model';
import { OrderDTO } from './order.dto';
import { TicketVariationDTO } from './ticket-variation.dto';
import { TicketDTO } from './ticket.dto';
import { RRPPDTO } from './rrpp.dto';
import { VariationAddOnDTO } from './variation-addon.dto';

export class OrderItemDTO {
  id: number;
  quantity: number;
  order?: OrderDTO;
  tickets?: TicketDTO[];
  stockLine?: any;
  rrpp?: RRPPDTO;
  unitPrice: number;
  unitAddon: number;
  unitFee: number;
  unitRrppFee: number;
  ticket_variation?: TicketVariationDTO;
  variation_add_ons: VariationAddOnDTO[];

  static forClient(entity: OrderItemDTO): OrderItem {
    const model = new OrderItem();

    model.id = entity.id;
    model.quantity = entity.quantity;
    model.unitPrice = entity.unitPrice;
    model.unitFee = entity.unitFee;
    model.unitRrppFee = entity.unitRrppFee;
    model.unitAddon = entity.unitAddon;

    if (entity.ticket_variation) {
      model.variation = TicketVariationDTO.forClient(entity.ticket_variation);
    }

    if (entity.tickets) {
      model.tickets = entity.tickets.map((tk) => TicketDTO.forClient(tk));
    }

    if (entity.order) {
      model.order = OrderDTO.forClient(entity.order);
    }

    if (entity.rrpp) {
      model.rrpp = RRPPDTO.forClient(entity.rrpp);
    }

    if (entity.variation_add_ons) {
      model.variationAddons = entity.variation_add_ons.map((va) => VariationAddOnDTO.forClient(va));
    }

    return model;
  }
}
