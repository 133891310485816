import { Address } from '../generic/address.model';
import { Population } from './population.model';

export class Place {
  id: number;
  name: string;
  population?: Population;
  address: Address;
  locationX?: string;
  locationY?: string;

  static create(place: Partial<Place>): Place {
    const model = new Place();

    model.id = place.id;
    model.name = place.name;
    model.address = place.address;
    model.locationX = place.locationX;
    model.locationY = place.locationY;

    if (place.population) {
      model.population = Population.create(place.population);
    }

    if (place.address) {
      model.address = Address.create(place.address);
    }

    return model;
  }
}
