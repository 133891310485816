import { Place } from '../models';
import { City } from '../models/event/city.model';
import { Population } from '../models/event/population.model';
import { Address } from '../models/generic/address.model';
import { AddressDTO } from './address.dto';
import { CityDTO } from './city.dto';
import { PlaceDTO } from './place.dto';

export class PopulationDTO {
  id: number;
  name: string;
  city?: CityDTO;
  addresses?: AddressDTO[];
  places?: PlaceDTO[];

  static forClient(entity: PopulationDTO): Population {
    const model = new Population();

    model.id = entity.id;
    model.name = entity.name;

    if (entity.city) {
      model.city = CityDTO.forClient(entity.city) as City;
    } else {
      model.city = null;
    }

    if (entity.addresses) {
      model.addresses = entity.addresses.map((add) => AddressDTO.forClient(add) as Address);
    }

    if (entity.places) {
      model.places = entity.places.map((place) => PlaceDTO.forClient(place) as Place);
    }

    return model;
  }
}
