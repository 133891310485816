import { Event } from "./event.model";
import { StockLine } from "./stock-line.model";
import { TicketVariation } from "./ticketVariation.model";

export class Stock {
  id: number;
  event?: Event;
  ticketVariation?: TicketVariation;
  stockLines: StockLine[];
  limit?: number;
  remaining?: number;
}
